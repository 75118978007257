import { Pipe, PipeTransform } from '@angular/core';
import { StatusSchedulingEnum } from '../../enum/medical-record/status-scheduling.enum';
import { DateTimeStruct } from '../../services/structs/orchestrator-patient/date-time.struct';
import { MedicalPrescriptionCheckStruct } from '../../services/structs/orchestrator-patient/medical-prescription-check.struct';
import { MedicalProcedureCheckStruct } from '../../services/structs/orchestrator-patient/medical-procedure-check.struct';
import { FrequencyEnum } from '../../enum/medical-record/frequency.enum';

@Pipe({
  name: 'filterStatusPrescriptionScheduling',
  pure: false
})
export class FilterStatusPrescriptionSchedulingPipe implements PipeTransform {

  transform(value: any, isTherapeutic: boolean, idFrequency: number, functionName: string, ...args: [DateTimeStruct[], string, number]): any {
    switch (functionName) {
      case 'getStatusPrescription':
        return this.getStatusPrescription(value, isTherapeutic, idFrequency, ...args);
      case 'getStatusProcedure':
        return this.getStatusProcedure(value, ...args);
      default:
        throw new Error(`Função ${functionName} não implementada.`);
    }
  }

  getStatusPrescription(listMedicalPrescriptionCheck: MedicalPrescriptionCheckStruct[], isTherapeutic: boolean, idFrequency: number, listDateTime: DateTimeStruct[], date: string, hour: number): string {
    if ((!listMedicalPrescriptionCheck || listMedicalPrescriptionCheck.length == 0)
      && listDateTime && listDateTime[0].date == date
      && listDateTime[0].hour[0] == hour) {
      if (idFrequency && idFrequency == FrequencyEnum.agora) {
        if (isTherapeutic)
          return 'hide';
        else if (listMedicalPrescriptionCheck?.[-1]?.idStatusScheduling === StatusSchedulingEnum.Concluido)
          return 'concluido';
        else if (listMedicalPrescriptionCheck?.[-1]?.idStatusScheduling === StatusSchedulingEnum.Suspenso)
          return 'suspenso';
        else if (!isTherapeutic)
          return 'agora';
      }
      else
        return '';
    }
    else if (!listMedicalPrescriptionCheck || listMedicalPrescriptionCheck.length == 0)
      return 'hide';
    else if (listMedicalPrescriptionCheck.length > 0) {
      let [day, month, year] = date.split('/').map(Number);
      let dateStatus = new Date(year, (month - 1), day);

      let verify = listMedicalPrescriptionCheck.find(x => {
        let formatDate = new Date(x.datetimeCheckEstimative);
        return formatDate.getFullYear() == dateStatus.getFullYear()
          && formatDate.getMonth() == dateStatus.getMonth()
          && formatDate.getDate() == dateStatus.getDate()
          && formatDate.getHours() == hour
      });

      if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Pendente)
        return 'pendente';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.AAprazar)
        return 'a-prazar';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Aprazado)
        return 'aprazado';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Concluido)
        return 'concluido';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Atrasado)
        return 'atrasado';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Suspenso)
        return 'suspenso';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Agora)
        return 'agora';
      else return 'hide';
    }

    return 'hide';
  }

  getStatusProcedure(listMedicalProcedureCheck: MedicalProcedureCheckStruct[], listDateTime: DateTimeStruct[], date: string, hour: number): string {
    if ((!listMedicalProcedureCheck || listMedicalProcedureCheck.length == 0)
      && listDateTime && listDateTime[0].date == date
      && listDateTime[0].hour[0] == hour) {
      return '';
    } else if (!listMedicalProcedureCheck || listMedicalProcedureCheck.length == 0) {
      return 'hide';
    } else if (listMedicalProcedureCheck.length > 0) {
      let [day, month, year] = date.split('/').map(Number);
      let dateStatus = new Date(year, (month - 1), day);
      let verify = listMedicalProcedureCheck.find(x => {
        let formatDate = new Date(x.datetimeCheckEstimative);
        return formatDate.getFullYear() == dateStatus.getFullYear()
          && formatDate.getMonth() == dateStatus.getMonth()
          && formatDate.getDate() == dateStatus.getDate()
          && formatDate.getHours() == hour
      });

      if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Pendente)
        return 'pendente';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.AAprazar)
        return 'a-aprazar';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Aprazado)
        return 'aprazado';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Concluido)
        return 'concluido';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Atrasado)
        return 'atrasado';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Suspenso)
        return 'suspenso';
      else if (verify && verify.idStatusScheduling == StatusSchedulingEnum.Agora)
        return 'agora';
      else return 'hide';
    }

    return 'hide';
  }

}

