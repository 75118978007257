import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const minOneHourDifferenceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const datesArray = control.value;
  if (!Array.isArray(datesArray) || datesArray.length < 2) {
    return null;
  }

  const errors: ValidationErrors[] = [];

  for (let i = 1; i < datesArray.length; i++) {
    const prevDate = parseDateTime(datesArray[i - 1]);
    const currentDate = parseDateTime(datesArray[i]);

    if (prevDate && currentDate) {
      const diffInMinutes = (currentDate.getTime() - prevDate.getTime()) / 60000;
      if (diffInMinutes < 60) {
        errors[i] = { minOneHourDifference: true };
      }
    }
  }

  control['controls'].forEach((ctrl: AbstractControl, index: number) => {
    const hourControl = ctrl.get('hour');
    if (hourControl) {
      hourControl.setErrors(errors[index] || null);
      if (errors[index]) {
        hourControl.markAsTouched();
        hourControl.markAsDirty();

        if (!hourControl['__isUpdating']) { 
          hourControl['__isUpdating'] = true; 
          hourControl.updateValueAndValidity();
          setTimeout(() => hourControl['__isUpdating'] = false, 0); 
        }

      }
    }
  });

  if (!control['__isUpdating']) {
    control['__isUpdating'] = true;
    control.updateValueAndValidity();
    setTimeout(() => control['__isUpdating'] = false, 0);
  }

  return errors.some(error => error !== undefined) ? { minOneHourDifference: true } : null;
};

function parseDateTime(obj: any): Date | null {
  if (!obj || !obj.date || !obj.hour) return null;

  const day = obj.date.substring(0, 2);
  const month = obj.date.substring(2, 4);
  const year = obj.date.substring(4, 8);
  const hour = obj.hour.substring(0, 2);
  const minutes = obj.hour.substring(2, 4);

  return new Date(`${year}-${month}-${day}T${hour}:${minutes}:00`);
}