<div mat-dialog-content>
    <div class="mat-dialog-content">
      
        <div class="title-modal">
            <h1>Score News</h1>
        </div>
        <div>
            <p><strong>Usuário:</strong> {{data.userName || ''}}</p>
            <p><strong>Data:</strong> {{data.datetimeInclusion ? (data.datetimeInclusion | date:'dd/MM/yyyy HH:mm:ss') : ''}}</p>
            <p><strong>Valor:</strong> {{data.totalScore || ''}}</p>            
        </div>
  
     
        <button mat-flat-button color="primary" class="button-close-modal"
            (click)="close()">Fechar</button>
        
    </div>
</div>