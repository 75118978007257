<h1 mat-dialog-title>Glicemia</h1>
<div mat-dialog-content>
    <div class="modal-body text-center">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()" [appUppercaseForm]="this.setUppercaseText">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Glicemia</mat-label>
                        <input *ngIf="this.isMasked" [value]="data.discriminatorsValues[this.discriminatorType]"
                            [mask]="masks.threeDigits" type="text" matInput formControlName="glucose">
                        <input *ngIf="!this.isMasked" [value]="data.discriminatorsValues[this.discriminatorType]"
                            type="text" matInput formControlName="glucose">
                        <span matSuffix>mg/dl</span>
                    </mat-form-field>
                    <div class="checkboxOOR">
                        <mat-checkbox (change)="glucoseOOR('Hi')" formControlName="high">Hi</mat-checkbox>
                        <mat-checkbox (change)="glucoseOOR('Lo')" formControlName="low">Lo</mat-checkbox>
                    </div>
                </div>
            </div>
            <div *ngIf="data.classificationData.priorityAutistic && !data.discriminatorsValues[this.discriminatorType]"
                class="row">
                <div class="col-12">
                    <mat-slide-toggle class="priorityAutistic" (change)="changePriorityAutistic($event)"
                        formControlName="priorityAutistic">
                        Não é possível aferir
                    </mat-slide-toggle>
                </div>
                <div *ngIf="autisticChecked" class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo</mat-label>
                        <textarea type="text" matInput formControlName="discriminatorSelectionMotive"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-panel-modal-list">
                <div class="row">
                    <div class="col-12">
                        <button type="submit" mat-flat-button color="primary" class="btn-block">Concluir</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>