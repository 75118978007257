<div class="row margin-box-bottom">
    <div class="col-12 col-md-4">
        <div class="status-caller" [ngStyle]="{'background-color': triage.gravityColor}">
            <span class="text-bold">{{ triage.gravityName }}</span>
        </div>
    </div>
</div>
<div *ngIf="isHistorical" class="row">
    <div class="col-6 col-sm-4 col-md-2">
        <h3 class="title-box">Usuário classificador</h3>
        <p class="content-box">{{triage.userClassificationName}}</p>
    </div>
    <div class="col-6 col-sm-4 col-md-2">
        <h3 class="title-box">CRM/COREN</h3>
        <p class="content-box">{{triage.userCouncilNumber}}</p>
    </div>
    <div class="col-6 col-sm-4 col-md-2">
        <h3 class="title-box">Data e hora inicial</h3>
        <p class="content-box">{{triage.startClassification | date:'dd/MM/yy, H:mm' }}</p>
    </div>
    <div *ngIf="hasEndDateTime" class="col-6 col-sm-4 col-md-2">
        <h3 class="title-box">Data e hora final</h3>
        <p class="content-box">{{triage.dateTimeInclusion | date:'dd/MM/yy, H:mm' }}</p>
    </div>
    <div *ngIf="hasEndDateTime" class="col-6 col-sm-4 col-md-2">
        <h3 class="title-box">Tempo de atendimento</h3>
        <p class="content-box">{{triage.serviceTime}}</p>
    </div>
    <div *ngIf="!isTelephonceClassification" class="col-6 col-sm-4 col-md-2">
        <h3 class="title-box">Sala</h3>
        <p class="content-box">{{triage.roomAttendanceName}}</p>
    </div>
</div>
<div class="row margin-box-bottom">
    <div class="col-12 col-sm-12 col-md-12">
        <div class="green-box">
            <h2 class="title-box">Queixa/Motivo</h2>
            <p>{{triage.complaint}}</p>
        </div>
    </div>
</div>
<div *ngIf="triage.observation" class="row margin-box-bottom">
    <div class="col-12 col-sm-12 col-md-12">
        <div class="green-box">
            <h2 class="title-box">Observação</h2>
            <p>{{triage.observation}}</p>
        </div>
    </div>
</div>
<div class="row">
    <div *ngIf="allergy" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Alergias</h3>
        <p class="content-box">{{allergy}}</p>
    </div>
    <div class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Protocolo</h3>
        <p class="content-box">{{protocolName}}</p>
    </div>
    <div *ngIf="triage.flowchart" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Fluxograma</h3>
        <p class="content-box">{{triage.flowchart}}</p>
    </div>
    <div *ngIf="triage.lastFlowchart" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Fluxograma Anterior</h3>
        <p class="content-box">{{triage.lastFlowchart}}</p>
    </div>
    <div class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Discriminador</h3>
        <p class="content-box">{{triage.discriminator}}</p>
    </div>
    <div *ngIf="triage.temperature" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Temperatura</p>
        <p class="content-box">{{triage.temperature}}<span class="measures-value-label">°C</span></p>
    </div>
    <div *ngIf="triage.respiratoryFrequency" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Fr. Respiratória</p>
        <p class="content-box">{{triage.respiratoryFrequency}}<span class="measures-value-label">irpm</span></p>
    </div>
    <div *ngIf="triage.heartRate" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Fr. Cardíaca</p>
        <p class="content-box">{{triage.heartRate}}<span class="measures-value-label">bpm</span></p>
        <p class="title-box">
            {{triage.heartRateRegular === true?"Regular": triage.heartRateRegular === false ?"Irregular": ""}}
            {{triage.isHeartRateSleeping === true? ' - Dormindo' : triage.isHeartRateSleeping === false? ' - Acordado' :
            ''}}
        </p>
    </div>
    <div *ngIf="triage.glucose || triage.glucoseOOR || triage.glucoseComplementaryData" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Glicemia</p>
        <p *ngIf="triage.glucose || triage.glucoseComplementaryData" class="content-box">{{triage.glucose? triage.glucose : triage.glucoseComplementaryData}}<span class="measures-value-label">mg/dL</span>
        </p>
        <p *ngIf="triage.glucoseOOR" class="content-box">{{triage.glucoseOOR}}</p>
    </div>
    <div *ngIf="triage.bloodPressureSystole && triage.bloodPressureDiastole"
        class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Pressão Arterial</p>
        <p class="content-box">{{triage.bloodPressureSystole && triage.bloodPressureDiastole ?
            triage.bloodPressureSystole + "/" + triage.bloodPressureDiastole : null}}<span
                class="measures-value-label">mmHg</span></p>
    </div>
    <div *ngIf="triage.saturation" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Saturação</p>
        <p class="content-box">{{triage.saturation}}<span class="measures-value-label">%</span></p>
        <p class="title-box">{{triage.atmosphericAir === true?"Ar Atmosférico": triage.atmosphericAir ===
            false ?"Em terapia de O2": ""}}</p>
    </div>
    <div *ngIf="pain != null" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Dor</p>
        <p class="content-box">{{pain}}</p>
        <p class="title-box">{{painType>=min_less_seven && painType <= max_less_seven ?"Menos de 7 dias": painType>=
                min_over_seven && painType <= max_over_seven ?"Mais de 7 dias": "" }}</p>
    </div>
    <div *ngIf="triage.glasgow" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Glasgow </p>
        <p class="content-box">{{triage.glasgow}}</p>
    </div>
    <div *ngIf="triage.discriminatorSelectionMotive" class="col-12 measures-box">
        <p class="title-box">Motivo(Não foi possível aferir os sinais vitais): </p>
        <p class="content-box">{{triage.discriminatorSelectionMotive}}</p>
    </div>

    <div *ngIf="triage.weight" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Peso</p>
        <p class="content-box">{{triage.weight}}<span class="measures-value-label">Kg</span></p>
    </div>
    <div *ngIf="triage.height" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Altura</p>
        <p class="content-box">{{triage.height | number : '1.2'}}<span class="measures-value-label">m</span></p>
    </div>
    <div *ngIf="triage.fallRisk === true || triage.fallRisk === false" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Risco de queda</p>
        <p class="content-box">{{triage.fallRisk === true?"SIM":triage.fallRisk === false? "NÃO": ""}}</p>
    </div>
    <div *ngIf="triage.suspicionName && !isHistorical" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Suspeitas</p>
        <p class="content-box">{{triage.suspicionName}}</p>
    </div>
    <div *ngIf="triage.diseaseHistory" class="col-6 col-sm-4 col-md-2 measures-box">
        <p class="title-box">Comorbidades</p>
        <p class="content-box">{{triage.diseaseHistory}}</p>
    </div>
    <div *ngIf="!isTelephonceClassification && triage.forwardName" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Encaminhamento</h3>
        <p class="content-box">{{triage.forwardName}}</p>
    </div>
    <div *ngIf="isTelephonceClassification && triage.listCounseling" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Aconselhamentos</h3>
        <p *ngFor="let couseling of triage.listCounseling" class="content-box">{{couseling.counselingName}}</p>
    </div>
    <div *ngIf="specialNecessityName" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Necessidades Especiais</h3>
        <p class="content-box">{{specialNecessityName}}</p>
    </div>
    <div *ngIf="triage.capillaryFillingTime != null && !triage.capillaryFillingTimeValue"
        class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Perfusão Capilar</h3>
        <p class="content-box">{{triage.capillaryFillingTime === false ?' Menor que 2 segundos (\
            <2s)':(triage.capillaryFillingTime===true ? 'maior que 2 segundos (>2s)' : '' ) }}</p>
    </div>
    <div *ngIf="triage.capillaryFillingTimeValue" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Perfusão Capilar</h3>
        <p class="content-box">{{triage.capillaryFillingTimeValue}} Segundos</p>
    </div>
    <!-- <div class="col-12 col-sm-6 col-md-3" *ngIf="isTeletriage">
        <button mat-flat-button type="button" (click)="back()" color="accent" class=" btn-block">
            <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon>
            <span *ngIf="!isLoading">Voltar</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div> -->
    <div *ngIf="triage.healthGuidelineName" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Protocolo de saúde</h3>
        <p class="content-box">{{triage.healthGuidelineName}}</p>
    </div>
    <div *ngIf="triage.categorizationName" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Categorização do protocolo de saúde</h3>
        <p class="content-box">{{triage.categorizationName}}</p>
    </div>
    <div *ngIf="triage.datetimeStartSymptoms" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Data de início do sintoma</h3>
        <p class="content-box">{{triage.datetimeStartSymptoms | date:'dd/MM/yy, H:mm' }}</p>
    </div>
</div>
<div class="row" *ngIf="isHistorical">
    <h2>Dados Complementares da Triagem</h2>
    <div *ngIf="triage.temperatureComplementaryData" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Temperatura</h3>
        <p class="content-box">{{triage.temperatureComplementaryData}}</p>
    </div>
    <div *ngIf="triage.bloodPressureDiastoleComplementaryData && triage.bloodPressureSystoleComplementaryData"
        class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Pressão Arterial</h3>
        <p class="content-box">{{triage.bloodPressureSystoleComplementaryData &&
            triage.bloodPressureDiastoleComplementaryData ?
            triage.bloodPressureSystoleComplementaryData + "/" + triage.bloodPressureDiastoleComplementaryData :
            null}}<span class="measures-value-label">mmHg</span></p>
    </div>
    <div *ngIf="triage.heartRateComplementaryData" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Fr. Cardíaca</h3>
        <p class="content-box">{{triage.heartRateComplementaryData}}</p>
    </div>
    <div *ngIf="triage.respiratoryFrequencyComplementaryData" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Fr. Respiratória</h3>
        <p class="content-box">{{triage.respiratoryFrequencyComplementaryData}}</p>
    </div>
    <div *ngIf="triage.glucoseComplementaryData" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Glicemia</h3>
        <p class="content-box">{{triage.glucoseComplementaryData}}</p>
    </div>
    <div *ngIf="triage.saturationComplementaryData" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Saturação</h3>
        <p class="content-box">{{triage.saturationComplementaryData}}</p>
    </div>
    <div *ngIf="triage.justification" class="col-6 col-sm-4 col-md-2 measures-box">
        <h3 class="title-box">Observação</h3>
        <p class="content-box">{{triage.justification}}</p>
    </div>
</div>
<div class="row" *ngIf="isTeletriage && patientForm != null">
    <div class="title-header">
        <div class="row">
            <div class="col-6 col-sm-6 col-md-6">
                <h1>Dados Pessoais</h1>
            </div>
        </div>
    </div>
    <form class="form" [formGroup]="patientForm">
        <div class="row">
            <div class="col-12 col-sm-4 col-md-4">
                <mat-form-field>
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" readonly formControlName="patientName">
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <mat-form-field>
                    <mat-label>Data de Nascimento</mat-label>
                    <input matInput type="text" readonly [mask]="masks.date" formControlName="birthDate">
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <mat-form-field>
                    <mat-label>Idade</mat-label>
                    <input matInput type="text" readonly formControlName="patientAge">
                </mat-form-field>
            </div>
        </div>
    </form>

</div>
<div *ngIf="isTeletriage" class="row">
    <div class="offset-6 col-6 offset-md-8 col-md-4">
        <button mat-flat-button (click)="submit()" color="primary" class=" btn-block">
            <span *ngIf="!isLoading">Salvar</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>
</div>
<div class="row" *ngIf="this.triage.triageReportName && isHistorical">
    <div class="col-12 col-sm-6 col-md-2">
        <button mat-flat-button color="primary" class=" btn-block" (click)="downloadTriageReport()">
            <span *ngIf="!isLoading">Relatório de Classificação</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
    </div>
</div>