import { Injectable } from '@angular/core';
import { FilterSelectModel } from '../components/filter-select/filter-select.model';
import { UserLoginResponse } from './responses/user/auth.response';
import { ModuleStruct } from './structs/user/module.struct';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { CommonService } from './common-service';
import { UserHasModuleStruct } from '../structs/user-has-module.struct';
import { RoleStruct } from './structs/user/role.struct';
import { MemedPrescriptionStruct } from './structs/medical-record/memed-prescription.struct';
import { MemedCompletePrescriptionStruct } from './structs/medical-record/memed-complete-prescription.struct';

@Injectable({
  providedIn: 'root'
})
export class UtilService extends CommonService {

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  creatInitials(userName): string {
    if (userName.split(' ').length == 2)
      return userName.split(' ')[0][0] + userName.split(' ')[1][0];
    else
      return userName[0];
  }

  setIntegrationEvade(hasSiga: boolean): void {
    sessionStorage.setItem('hasSigaIntegration', hasSiga.toString());
  }

  getIntegrationEvade(): boolean {
    let hasSiga = sessionStorage.getItem('hasSigaIntegration');
    return hasSiga == "true" ? true : false;
  }

  getModules(): ModuleStruct[] {
    let token: UserLoginResponse = JSON.parse(sessionStorage.getItem('token'));
    return token.listModule;
  }

  getRoles(): RoleStruct[] {
    let token: UserLoginResponse = JSON.parse(sessionStorage.getItem('token'));
    return token.listRole;
  }

  getToken(): UserLoginResponse {
    const data: string = sessionStorage.getItem('token');
    let token: UserLoginResponse = data ? JSON.parse(sessionStorage.getItem('token')) : null;
    return token;
  }

  updateToken(userLoginResponse: UserLoginResponse) {
    sessionStorage.setItem("token", JSON.stringify(userLoginResponse));
  }

  getKeySelectedModel(filterSelectModel: FilterSelectModel) {
    if (filterSelectModel == null)
      return null;

    let listKeys: number[] = [];

    filterSelectModel.itemSelectModel.forEach(element => {
      if (element.checked)
        listKeys.push(parseInt(element.key))
    });

    if (listKeys.length == 0)
      return null;

    return JSON.stringify(listKeys);
  }

  setSecundaryHeaderModuleConfig(data: any, moduleName: string): void {
    sessionStorage.setItem(moduleName, JSON.stringify(data));
  }

  getSecundaryHeaderModuleConfig(moduleName: string): any {
    const data: string = sessionStorage.getItem(moduleName);
    return data ? JSON.parse(data) : false
  }

  cleanSecundaryHeaderModuleConfig(moduleName: string): void {
    sessionStorage.removeItem(moduleName);
  }

  getReturnToQueueConfig(): boolean {
    const data: string = sessionStorage.getItem('token');

    let token: UserLoginResponse = data ? JSON.parse(data) : null;

    return token ? token.healthUnit.canReturnToQueue : true;
  }

  getAge(dateString) {
    var daysInMonth = 30.436875;
    let dob;
    if (dateString instanceof Date)
      dob = dateString;
    else {
      let date = this.toISOFormat(dateString);
      dob = new Date(date);
    }
    let aad = new Date();
    let yearAad = aad.getFullYear();
    let yearDob = dob.getFullYear();
    let years = yearAad - yearDob;
    dob.setFullYear(yearAad);
    let aadMillis = aad.getTime();
    let dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1);
      dobMillis = dob.getTime();
    }
    let days = (aadMillis - dobMillis) / 86400000;
    let monthsDec = days / daysInMonth;
    let months = Math.floor(monthsDec);
    days = Math.floor(daysInMonth * (monthsDec - months));

    return { years: years, months: months, days: days };
  }
  getAgeString(dateString) {
    var daysInMonth = 30.436875;
    let dob;
    if (dateString instanceof Date)
      dob = dateString;
    else {
      let date = this.toISOFormat(dateString);
      dob = new Date(date);
    }
    let aad = new Date();
    let yearAad = aad.getFullYear();
    let yearDob = dob.getFullYear();
    let years = yearAad - yearDob;
    dob.setFullYear(yearAad);
    let aadMillis = aad.getTime();
    let dobMillis = dob.getTime();
    if (aadMillis < dobMillis) {
      --years;
      dob.setFullYear(yearAad - 1);
      dobMillis = dob.getTime();
    }
    let days = (aadMillis - dobMillis) / 86400000;
    let monthsDec = days / daysInMonth;
    let months = Math.floor(monthsDec);
    days = Math.floor(daysInMonth * (monthsDec - months));

    return `${years}a ${months}m ${days}d`;
  }

  toISOFormat(dateTimeString: string) {
    let newDate = dateTimeString.replaceAll('/', '').replaceAll('-','');
    let DD = newDate.substring(0, 2);
    let MM = newDate.substring(2, 4);
    let YYYY = newDate.substring(4, 8);

    return `${YYYY}-${MM}-${DD}`;
  }

  setKpiSectors(listIdSectors: number[]) {
    sessionStorage.setItem("kpiSectors", JSON.stringify(listIdSectors))
  }

  readKpiSectors() {
    const listIdSectors = sessionStorage.getItem("kpiSectors");
    if (listIdSectors) {
      return JSON.parse(listIdSectors);
    }
  }

  validateUserHasModuleList(listModuleToVerify: number[]): UserHasModuleStruct[] {
    const data: string = sessionStorage.getItem('token');
    let token: UserLoginResponse = data ? JSON.parse(sessionStorage.getItem('token')) : null;

    let listUserHasModule: UserHasModuleStruct[] = [];
    let userHasModule = new UserHasModuleStruct();

    if (token && token.listModule && listModuleToVerify) {
      listModuleToVerify.forEach(element => {
        userHasModule.userHasModule = token.listModule.some(moduleStruct => moduleStruct.idModule == element && moduleStruct.isModulePermitted);
        userHasModule.idModule = element;

        listUserHasModule.push(userHasModule);
        userHasModule = new UserHasModuleStruct();
      })
    }

    return listUserHasModule;
  }

  setGuidStayConnected(stayConnected: StayConnected): void {
    localStorage.setItem('stayConnected', JSON.stringify(stayConnected));
  }

  getGuidStayConnected(): StayConnected {
    let stayConnected: StayConnected = JSON.parse(localStorage.getItem('stayConnected'));
    return stayConnected;
  }

  cleanGuidStayConnected(): void {
    localStorage.removeItem("stayConnected");
  }

  setPreviousURL(previousURL: any): void {
    sessionStorage.setItem('previousURL', previousURL);
  }

  getPreviousURL(): string {
    let previousURL: string = sessionStorage.getItem('previousURL');
    return previousURL;
  }

  cleanPreviousURL(): void {
    sessionStorage.removeItem("previousURL");
  }

  public getIP(): Observable<IPReturn> {

    return this.httpClient.get<IPReturn>("https://api.ipify.org/?format=json")
      .pipe(
        catchError(this.handleError)
      )
  }

  setColumnFlowManagement(listColumnToDisplay: number[]): void {
    sessionStorage.setItem('listColumnFlowManagement', JSON.stringify(listColumnToDisplay));

  }

  getColumnFlowManagement() {
    const listColumnFlowManagement = sessionStorage.getItem("listColumnFlowManagement");
    if (listColumnFlowManagement) {
      return JSON.parse(listColumnFlowManagement);
    }
  }

  setMemedPrescriptionSession(memedPrescriptionSession: MemedPrescriptionSession): void {
    sessionStorage.setItem('memedPrescriptionSession', JSON.stringify(memedPrescriptionSession));
  }

  getMemedPrescriptionSession(): MemedPrescriptionSession {
    let memedPrescriptionSession: MemedPrescriptionSession = JSON.parse(sessionStorage.getItem('memedPrescriptionSession'));
    return memedPrescriptionSession;
  }

  cleanMemedPrescriptionSession(): void {
    sessionStorage.removeItem("memedPrescriptionSession");
  }

  setNotificationOpened(open: boolean): void {
    sessionStorage.setItem('notificationAlreadyOpened', JSON.stringify(open));
  }

  getNotificationOpened(): boolean {
    let notificationAlreadyOpened: string = sessionStorage.getItem('notificationAlreadyOpened');
    return notificationAlreadyOpened == "true" ? true : false;
  }

  consoleLog(log: string) {
    console.log(log);
  }

  updateObservationIdService(idService: number){
    sessionStorage.setItem('observationIdService', JSON.stringify(idService))
  }

  removeObservationIdService() {
    sessionStorage.removeItem("observationIdService");
  }

  getObservationIdService(){
    let ObservationIdService: number = JSON.parse(sessionStorage.getItem('observationIdService'))
    return ObservationIdService ? ObservationIdService : null;
  }

  listAllMonths(){
    let listMonths: { id: number, name: string }[] = [
      { "id": 1, "name": "Janeiro" }, { "id": 2, "name": "Fevereiro" },
      { "id": 3, "name": "Março" }, { "id": 4, "name": "Abril" },
      { "id": 5, "name": "Maio" }, { "id": 6, "name": "Junho" },
      { "id": 7, "name": "Julho" }, { "id": 8, "name": "Agosto" },
      { "id": 9, "name": "Setembro" }, { "id": 10, "name": "Outubro" },
      { "id": 11, "name": "Novembro" }, { "id": 12, "name": "Dezembro" },
    ];
    return listMonths;
  }
  
  validatorCns(cns: any) {
    let cnsString = cns.toString();

    var validateSize = (cnsString.length == 15)
    var validateStart = ['1', '2', '7', '8', '9'].includes(cnsString[0])

    if (validateSize && validateStart) {
      //CNS Iniciados em 7, 8 ou 9
      if (['7', '8', '9'].includes(cnsString[0])) {
        var sum = cnsString.split('').reduce((total, cns, index) => total + (cns * (15 - index)), 0);
        if (sum % 11 != 0) {
          return false;
        } else {
          return true;
        }

      } else if (['1', '2'].includes(cnsString[0])) {
        //CNS Iniciados em 1, 2
        var pis = cnsString.substring(0, 11);
        var sum = pis.split('').reduce((total, cns, index) => total + (cns.valueOf() * (15 - index)), 0)

        var rest = sum % 11
        var dv = rest == 0 ? 0 : 11 - rest

        var result = dv == 10 ? `${pis}001${(11 - ((sum + 2) % 11))}` : `${pis}000${dv}`
        if (result != cnsString) {
          return false;
        } else {
          return true;
        }

      } else {
        return false;
      }

    } else {
      return false;
    }
  }

  validatorCpf(cpfString) {
    let isValid: boolean = true;

    if (cpfString.length != 11) {
      return false;
    }

    var Soma;
    var Resto;

    Soma = 0;
    if (cpfString == "00000000000" ||
      cpfString == "11111111111" ||
      cpfString == "22222222222" ||
      cpfString == "33333333333" ||
      cpfString == "44444444444" ||
      cpfString == "55555555555" ||
      cpfString == "66666666666" ||
      cpfString == "77777777777" ||
      cpfString == "88888888888" ||
      cpfString == "99999999999") {
      isValid = false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (11 - i)
    };

    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) {
      Resto = 0
    };

    if (Resto != parseInt(cpfString.substring(9, 10))) {
      isValid = false;
    };

    Soma = 0;

    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(cpfString.substring(i - 1, i)) * (12 - i)
    };
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) {
      Resto = 0;
    }

    if (Resto != parseInt(cpfString.substring(10, 11))) {
      isValid = false;
    }

    if (!isValid) {
      return false;
    } else {
      return true;
    }
  }

  validatorDate(data: string): boolean {
    const dataLimpa = data.replace(/\D/g, '');
  
    if (dataLimpa.length !== 8) {
      return false;
    }
  
    const dia = parseInt(dataLimpa.substring(0, 2), 10);
    const mes = parseInt(dataLimpa.substring(2, 4), 10);
    const ano = parseInt(dataLimpa.substring(4, 8), 10);
  
    if (mes < 1 || mes > 12) {
      return false;
    }
  
    const diasNoMes = new Date(ano, mes, 0).getDate();
    if (dia < 1 || dia > diasNoMes) {
      return false;
    }
  
    return true;
  }

  setIsBluetoothVerify(isBluetoothVerify: boolean) {
    sessionStorage.setItem("isBluetoothVerify", JSON.stringify(isBluetoothVerify));
  }

  getIsBluetoothVerify():boolean {
      let healthGuidelineTriageData: boolean = JSON.parse(sessionStorage.getItem("isBluetoothVerify"))
      return healthGuidelineTriageData;
  }

  removeIsBluetoothVerify() {
      sessionStorage.removeItem("isBluetoothVerify");
  }
}

export class IPReturn {
  public ip: string
}

export class StayConnected {
  public guid: string;
  public name: string;
  public healthUnit: string;
}

export class MemedPrescriptionSession {
  listMemedPrescriptionStruct: MemedPrescriptionStruct[] = [];
  listMemedCompletePrescriptionStruct: MemedCompletePrescriptionStruct[] = [];
}