export class MedicalProcedureCheckModel{

    public idMedicalProcedureCheck: number;
    
    public idUser: number;

    public idUserScheduling: number;
    
    public datetimeInclusion: Date;

    public idMedicalProcedure: number;

    public idNursingConsultation: number;
    
    public idStatusScheduling: number;

    public reasonSuspension: string;

    public checkProcedure: boolean;

    public datetimeCheckFulfilled: Date;
    
    public checkName: boolean;
    
    public schedulingObservation: string;
}