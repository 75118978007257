export class MedicalPrescriptionCheckModel{

    public idMedicalPrescriptionCheck: number;
    
    public idUser: number;
    
    public idUserScheduling: number;

    public idStatusScheduling: number;
    
    public checkName: boolean;
    
    public checkMedication: boolean;
    
    public checkAdministration: boolean;
    
    public hasPatientDenied: boolean;
    
    public hasComplaints: boolean;
    
    public complaints: string;

    public administrationLocal: string;

    public schedulingObservation: string;
    public reasonSuspension: string;
    
    public datetimeInclusion: Date;

    public idMedicalPrescription: number;

    public idNursingConsultation: number;

    public datetimeCheckEstimative: Date;

    public datetimeCheckFulfilled: Date;
}