<div class="secundary-header desktop">
    <div class="display-flex justify-between">
        <div>
            <div class="name-info display-flex">
                <div *ngIf="name">
                    <div class="name">
                        <span>{{name}}</span>
                    </div>
                    <div *ngIf="socialName" class="social-name">
                        <span>{{socialName}}</span>
                    </div>
                </div>
                <div *ngIf="!name">
                    <div class="name-not-found">
                        <span>Nenhum paciente chamado</span>
                    </div>
                </div>
            </div>
            <div class="display-flex">
                <div class="info-content">
                    <div>
                        <span class="text-bold">Nasc.:</span>
                        <span class="padding-left-1">{{birthDate ? formatedBirth : '-'}}</span>
                    </div>
                    <div>
                        <span class="text-bold">Idade:</span>
                        <span class="padding-left-1">{{age ? age : '-'}}</span>
                    </div>
                    <div>
                        <span class="text-bold">Linha de cuidado:</span>
                        <span class="padding-left-1">{{patientCareLinePriorityStruct ?
                            patientCareLinePriorityStruct.careLinePriorityName ?
                            (patientCareLinePriorityStruct.careLinePriorityName + ' - ' +
                            patientCareLinePriorityStruct.careLinePriorityStatusName ): '-' : '-'}} </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="status-content display-flex" *ngIf="discriminators">
            <div class="status-content__info">
                <span class="text-bold">Saturação</span>
                <span *ngIf="(discriminators && discriminators[discriminatorTypeEnum.Saturacao])">{{discriminators
                    && discriminators[discriminatorTypeEnum.Saturacao] ?
                    discriminators[discriminatorTypeEnum.Saturacao]: '-'}}</span>
                <span *ngIf="discriminators && !discriminators[discriminatorTypeEnum.Saturacao]" class="">-</span>
            </div>
            <div class="status-content__info">
                <span class="text-bold">Glicemia</span>
                <span *ngIf="(discriminators && discriminators[discriminatorTypeEnum.Glicemia])">{{discriminators
                    && discriminators[discriminatorTypeEnum.Glicemia] ?
                    discriminators[discriminatorTypeEnum.Glicemia] :'-'}}</span>
                <span *ngIf="discriminators && !discriminators[discriminatorTypeEnum.Glicemia]" class="">-</span>
            </div>
            <div class="status-content__info">
                <span class="text-bold">Pressão</span>
                <span *ngIf="(discriminators && discriminators[discriminatorTypeEnum.PressaoArterial])">{{discriminators
                    && discriminators[discriminatorTypeEnum.PressaoArterial] ?
                    discriminators[discriminatorTypeEnum.PressaoArterial] :'-'}}</span>
                <span *ngIf="discriminators && !discriminators[discriminatorTypeEnum.PressaoArterial]" class="">-</span>
            </div>
            <div class="status-content__info">
                <span class="text-bold">Temperatura</span>
                <span *ngIf="(discriminators && discriminators[discriminatorTypeEnum.Temperatura])">{{discriminators
                    && discriminators[discriminatorTypeEnum.Temperatura] ?
                    discriminators[discriminatorTypeEnum.Temperatura] :'-'}}</span>
                <span *ngIf="discriminators && !discriminators[discriminatorTypeEnum.Temperatura]" class="">-</span>
            </div>
            <div class="status-content__info">
                <span class="text-bold">Pulso</span>
                <span
                    *ngIf="(discriminators && discriminators[discriminatorTypeEnum.FrequenciaCardiaca])">{{discriminators
                    && discriminators[discriminatorTypeEnum.FrequenciaCardiaca] ?
                    discriminators[discriminatorTypeEnum.FrequenciaCardiaca] : '-'}}</span>
                <span *ngIf="discriminators && !discriminators[discriminatorTypeEnum.FrequenciaCardiaca]"
                    class="">-</span>
            </div>
            <div>
                <a *ngIf="isClassification" mat-flat-button matTooltip="Reiniciar medidores"
                    (click)="emitMeasurerRestart()" type="button" color="accent" class="btn-block">
                    <mat-icon class="reload-icon">sync</mat-icon>
                </a>
            </div>
        </div>
    </div>
</div>
<cdk-accordion class="accordion mobile">
    <cdk-accordion-item #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header'" [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body'">
        <div [ngClass]="{'accordion-item-header-expanded': accordionItem.expanded}" class="accordion-item-header"
            (click)="accordionItem.toggle()">
            <div class="name-info display-flex">
                <div *ngIf="name">
                    <div class="name truncate">
                        <span>{{name}}</span>
                    </div>
                    <div *ngIf="socialName" class="social-name truncate">
                        <span>{{socialName}}</span>
                    </div>
                </div>
                <div *ngIf="!name">
                    <div class="name-not-found">
                        <span>Nenhum paciente chamado</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body'" [attr.aria-labelledby]="'accordion-header'">
            <div class="info-content">
                <div class="line-height">
                    <span class="text-bold">Nasc.:</span>
                    <span class="padding-left-1">{{birthDate ? formatedBirth : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Idade:</span>
                    <span class="padding-left-1">{{age ? age : '-'}}</span>
                </div>
            </div>
        </div>
    </cdk-accordion-item>
</cdk-accordion>