<div class="secundary-header desktop">
    <div class="display-flex justify-between">
        <div>
            <div class="name-info display-flex">
                <div *ngFor="let item of selectedPriorities">
                    <mat-icon class="priority-icon"
                        *ngIf="item.idPriority !== this.overEightyYearsEnum && item.idPriority !== this.overSixtyYearsEnum && item.idPriority !== this.autismEnum">{{item.icon}}</mat-icon>
                        <img src="assets/images/autism-symbol.png" class="img-adjust" *ngIf="item.idPriority === this.autismEnum"/>    
                    <span class="priority-age"
                        *ngIf="item.idPriority === this.overEightyYearsEnum || item.idPriority === this.overSixtyYearsEnum">{{item.icon}}</span>
                </div>
                <div *ngIf="episodeData.name" >
                    <div class="name" matTooltip="Ver dados do paciente" #tooltip="matTooltip" (click)="openModalPatientDetails()">
                        <mat-icon class="name-icon" aria-hidden="false" aria-label="Detalhes">assignment_ind</mat-icon>
                        <span>
                            <span class="name-title">{{episodeData.isUnidentifiedPatient ? episodeData.name + ' - NÃO IDENTIFICADO': episodeData.name}}</span>
                            <span *ngIf="episodeData.isSchedule"> - <strong>Agendado para:</strong>
                                {{episodeData.dateStartSchedule | date: 'dd/MM/yyyy HH:mm'}}
                            </span>
                            <span *ngIf="episodeData.medicalSpecialityName != null"> -
                                {{episodeData.medicalSpecialityName}}
                            </span>
                        </span>
                    </div>
                    <a #tooltipLink (click)="tooltip.toggle()"></a>
                    <div *ngIf="episodeData.socialName" class="social-name">
                        <span><span>{{episodeData.name}}</span>
                            <span *ngIf="episodeData.isSchedule"> - <strong>Agendado para:</strong>
                                {{episodeData.dateStartSchedule | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <span *ngIf="episodeData.medicalSpecialityName != null"> -
                                {{episodeData.medicalSpecialityName}}</span>
                        </span>
                    </div>
                </div>
                <div class="status-caller"
                    [ngStyle]="{'background-color': gravityColorCode || episodeData.gravityColorCode}">
                    <span class="text-bold">{{gravity || episodeData.gravity ? gravity || episodeData.gravity :
                        '-'}}</span>
                </div>
                <div *ngIf="!episodeData.name">
                    <div class="name-not-found">
                        <span>Nenhum paciente chamado</span>
                    </div>
                </div>
            </div>
            <div class="display-flex">
                <div class="info-content">
                    <div>
                        <span class="text-bold">Senha:</span>
                        <span class="padding-left-1">{{episodeData.token ? episodeData.token : '-'}}</span>
                    </div>
                    <div>
                        <span class="text-bold">Episódio:</span>
                        <span class="padding-left-1">{{episodeData.idEpisode ? episodeData.idEpisode : '-'}}</span>
                    </div>
                    <div>
                        <span class="text-bold">Espera:</span>
                        <span class="padding-left-1">{{episodeData.waitingTime ? (episodeData.waitingTime) :
                            '-'}} </span>
                    </div>
                    <div>
                        <span class="text-bold">Nasc.:</span>
                        <span class="padding-left-1">{{episodeData.birthDate ? formatedBirth : '-'}}</span>
                    </div>
                    <div>
                        <span class="text-bold">Idade:</span>
                        <span class="padding-left-1">{{episodeData.age ? episodeData.age : '-'}}</span>
                    </div>
                    <div>
                        <span class="text-bold">Retorno:</span>
                        <span class="padding-left-1">{{episodeData.regress ? formatedRegress : '-'}}</span>
                    </div>
                    <div>
                        <span class="text-bold">Linha de cuidado:</span>
                        <span class="padding-left-1">{{episodeData.patientCareLinePriorityStruct ?
                            episodeData.patientCareLinePriorityStruct.careLinePriorityName ?
                            (episodeData.patientCareLinePriorityStruct.careLinePriorityName + ' - ' +
                            episodeData.patientCareLinePriorityStruct.careLinePriorityStatusName ): '-' : '-'}} </span>
                    </div>
                    <div>
                        <span class="text-bold">Suspeita:</span>
                        <span *ngIf="!isSuspicionEvaluated && !episodeData.suspicionName" class="padding-left-1"> -
                        </span>
                        <span
                            *ngIf="(isSuspicionEvaluated != true && isSuspicionEvaluated != false ) && episodeData.suspicionName"
                            class="padding-left-1">{{episodeData.suspicionName + ' - aguardando validação'}}</span>
                        <span
                            *ngIf="(isSuspicionEvaluated == true || isSuspicionEvaluated == false ) && episodeData.suspicionName"
                            class="padding-left-1">{{episodeData.suspicionName + ' - ' +
                            episodeData.suspicionStatusName}} </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="glasgow-and-pain" col-md-6>
            <div class="row">
                <a mat-flat-button (click)="clickVital('gla')" matTooltip="Visualizar Histórico" class="list-header">
                    <div class="glasgow-content">
                        <span class="text-bold">Glasgow</span>
                        <span>{{ discriminators && discriminators[discriminatorTypeEnum.Glasgow] ?
                            discriminators[discriminatorTypeEnum.Glasgow] : (episodeData.glasgow ?
                            episodeData.glasgow : '-') }}
                        </span>
                    </div>
                </a>
            </div>
            <div class="row">
                <a mat-flat-button (click)="clickVital('p')" matTooltip="Visualizar Histórico" class="list-header">
                    <div class="pain-content"
                        [ngStyle]="{'background-color': painColorCode[0] || episodeData.painColorCode}">
                        <span class="text-bold">Dor</span>
                        <span>{{ discriminators && discriminators[discriminatorTypeEnum.Dor] != null ?
                            discriminators[discriminatorTypeEnum.Dor] : (discriminators &&
                            discriminators[discriminatorTypeEnum.DorCoceira] != null ?
                            discriminators[discriminatorTypeEnum.DorCoceira] : (pain != null && pain != ''?
                            pain : (episodeData.pain != null && episodeData.pain != ''?
                            episodeData.pain : '-' )))}}
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <div class="status-content display-flex">
            <a mat-flat-button (click)="clickVital('s')" matTooltip="Visualizar Histórico" class="list-header">
                <div class="status-content__info">
                    <span class="text-bold">Saturação</span>
                    <span
                        *ngIf="(discriminators && discriminators[discriminatorTypeEnum.Saturacao]) || episodeData.saturation !== -1">{{discriminators
                        && discriminators[discriminatorTypeEnum.Saturacao] ?
                        discriminators[discriminatorTypeEnum.Saturacao]: (episodeData.saturation ?
                        episodeData.saturation :
                        '-')}}</span>
                    <mat-icon
                        *ngIf="discriminators && !discriminators[discriminatorTypeEnum.Saturacao] && episodeData.saturation === -1"
                        class="">cached</mat-icon>
                </div>
            </a>
            <a mat-flat-button (click)="clickVital('g')" matTooltip="Visualizar Histórico" class="list-header">
                <div class="status-content__info">
                    <span class="text-bold">Glicemia</span>
                    <span
                        *ngIf="(discriminators && discriminators[discriminatorTypeEnum.Glicemia]) || episodeData.glucose !== -1">{{discriminators
                        && discriminators[discriminatorTypeEnum.Glicemia] ?
                        discriminators[discriminatorTypeEnum.Glicemia] :
                        episodeData.glucose ? episodeData.glucose : '-'}}</span>
                    <mat-icon
                        *ngIf="discriminators && !discriminators[discriminatorTypeEnum.Glicemia] && episodeData.glucose === -1"
                        class="">cached</mat-icon>
                </div>
            </a>
            <a mat-flat-button (click)="clickVital('b')" matTooltip="Visualizar Histórico" class="list-header">
                <div class="status-content__info">
                    <span class="text-bold">Pressão</span>
                    <span
                        *ngIf="(discriminators && discriminators[discriminatorTypeEnum.PressaoArterial]) || episodeData.bloodPressure !== -1">{{discriminators
                        && discriminators[discriminatorTypeEnum.PressaoArterial] ?
                        discriminators[discriminatorTypeEnum.PressaoArterial] : episodeData.bloodPressure ?
                        episodeData.bloodPressure : '-'}}</span>
                    <mat-icon
                        *ngIf="discriminators && !discriminators[discriminatorTypeEnum.PressaoArterial] && episodeData.bloodPressure === -1"
                        class="">cached</mat-icon>
                </div>
            </a>
            <a mat-flat-button (click)="clickVital('t')" matTooltip="Visualizar Histórico" class="list-header">
                <div class="status-content__info">
                    <span class="text-bold">Temperatura</span>
                    <span
                        *ngIf="(discriminators && discriminators[discriminatorTypeEnum.Temperatura]) || episodeData.temperature !== -1">{{discriminators
                        && discriminators[discriminatorTypeEnum.Temperatura] ?
                        discriminators[discriminatorTypeEnum.Temperatura] : episodeData.temperature ?
                        episodeData.temperature :
                        '-'}}</span>
                    <mat-icon
                        *ngIf="discriminators && !discriminators[discriminatorTypeEnum.Temperatura] && episodeData.temperature === -1"
                        class="">cached</mat-icon>
                </div>
            </a>
            <a mat-flat-button (click)="clickVital('h')" matTooltip="Visualizar Histórico" class="list-header">
                <div class="status-content__info">
                    <span class="text-bold">Pulso</span>
                    <span
                        *ngIf="(discriminators && discriminators[discriminatorTypeEnum.FrequenciaCardiaca]) || episodeData.heartRate !== -1">{{discriminators
                        && discriminators[discriminatorTypeEnum.FrequenciaCardiaca] ?
                        discriminators[discriminatorTypeEnum.FrequenciaCardiaca] : episodeData.heartRate ?
                        episodeData.heartRate
                        :
                        '-'}}</span>
                    <mat-icon
                        *ngIf="discriminators && !discriminators[discriminatorTypeEnum.FrequenciaCardiaca] && episodeData.heartRate === -1"
                        class="">cached</mat-icon>
                </div>
            </a>
            <a mat-flat-button (click)="clickVital('f')" matTooltip="Visualizar Histórico" class="list-header">
                <div class="status-content__info">
                    <span class="text-bold">Freq. respiratória</span>
                    <span
                        *ngIf="(discriminators && discriminators[discriminatorTypeEnum.FrequenciaRespiratoria]) || episodeData.respiratoryFrequency !== -1">{{discriminators
                        && discriminators[discriminatorTypeEnum.FrequenciaRespiratoria] ?
                        discriminators[discriminatorTypeEnum.FrequenciaRespiratoria] : episodeData.respiratoryFrequency
                        ?
                        episodeData.respiratoryFrequency : '-'}}</span>
                    <mat-icon
                        *ngIf="discriminators && !discriminators[discriminatorTypeEnum.FrequenciaRespiratoria] && episodeData.respiratoryFrequency === -1"
                        class="">cached</mat-icon>
                </div>
            </a>
        </div>
        <div *ngIf="isClassification">
            <a mat-flat-button matTooltip="Reiniciar medidores" (click)="emitMeasurerRestart()" type="button"
                color="accent" class="btn-block">
                <mat-icon class="reload-icon">sync</mat-icon>
            </a>
        </div>
    </div>
</div>
<mat-progress-bar class="desktop" *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<cdk-accordion class="accordion mobile">
    <cdk-accordion-item #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header'" [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body'">
        <div [ngClass]="{'accordion-item-header-expanded': accordionItem.expanded}" class="accordion-item-header"
            (click)="accordionItem.toggle()">
            <div class="name-info display-flex">
                <div *ngIf="selectedPriorities">
                    <mat-icon class="priority-icon" *ngFor="let item of selectedPriorities">{{item.icon}}</mat-icon>
                </div>
                <div *ngIf="episodeData.name">
                    <div class="name truncate">
                        <button mat-flat-button matTooltip="Dados do paciente" type="button" color="secundary" class="btn-details" (click)="openModalPatientDetails()">
                            <mat-icon *ngIf="isLoading == false" aria-hidden="false" aria-label="Detalhes">assignment_ind</mat-icon>
                        </button>
                        <span>{{episodeData.name}}</span>
                    </div>
                    <div *ngIf="episodeData.socialName" class="social-name truncate">
                        <span>{{episodeData.socialName}}</span>
                    </div>
                </div>
                <div *ngIf="!episodeData.name">
                    <div class="name-not-found">
                        <span>Nenhum paciente chamado</span>
                    </div>
                </div>
            </div>
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body'" [attr.aria-labelledby]="'accordion-header'">
            <div class="info-content">
                <div class="line-height">
                    <span class="text-bold">Senha:</span>
                    <span class="padding-left-1">{{episodeData.token ? episodeData.token : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Episódio:</span>
                    <span class="padding-left-1">{{episodeData.idEpisode ? episodeData.idEpisode : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Espera:</span>
                    <span class="padding-left-1">{{episodeData.waitingTime ? episodeData.waitingTime : '-'}} min</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Linha de cuidado:</span>
                    <span class="padding-left-1">{{careLinePriority ? careLinePriority : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Suspeita:</span>
                    <span *ngIf="!isSuspicionEvaluated && !episodeData.suspicionName" class="padding-left-1"> - </span>
                    <span
                        *ngIf="(isSuspicionEvaluated != true && isSuspicionEvaluated != false ) && episodeData.suspicionName"
                        class="padding-left-1">{{episodeData.suspicionName + ' - aguardando validação'}}</span>
                    <span
                        *ngIf="(isSuspicionEvaluated == true || isSuspicionEvaluated == false ) && episodeData.suspicionName"
                        class="padding-left-1">{{episodeData.suspicionName + ' - ' + episodeData.suspicionStatusName}}
                    </span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Nasc.:</span>
                    <span class="padding-left-1">{{episodeData.birthDate ? formatedBirth : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Idade:</span>
                    <span class="padding-left-1">{{episodeData.age ? episodeData.age : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Retorno:</span>
                    <span class="padding-left-1">{{episodeData.regress ? formatedRegress : '-'}}</span>
                </div>
                <div class="status-caller line-height" [ngStyle]="{'background-color': episodeData.gravityColorCode}">
                    <span class="text-bold">{{episodeData.gravity ? episodeData.gravity : '-'}}</span>
                </div>
            </div>
            <div class="status-content display-flex">
                <div class="status-content__info">
                    <span class="text-bold">Saturação</span>
                    <span *ngIf="episodeData.saturation !== -1">{{episodeData.saturation ? episodeData.saturation :
                        '-'}}</span>
                    <mat-icon *ngIf="episodeData.saturation === -1" class="">cached</mat-icon>
                </div>
                <div class="status-content__info">
                    <span class="text-bold">Glicemia</span>
                    <span *ngIf="episodeData.glucose !== -1">{{episodeData.glucose ? episodeData.glucose : '-'}}</span>
                    <mat-icon *ngIf="episodeData.glucose === -1" class="">cached</mat-icon>
                </div>
                <div class="status-content__info">
                    <span class="text-bold">Pressão</span>
                    <span *ngIf="episodeData.bloodPressure !== -1">{{episodeData.bloodPressure ?
                        episodeData.bloodPressure : '-'}}</span>
                    <mat-icon *ngIf="episodeData.bloodPressure === -1" class="">cached</mat-icon>
                </div>
                <div class="status-content__info">
                    <span class="text-bold">Temperatura</span>
                    <span *ngIf="episodeData.temperature !== -1">{{episodeData.temperature ? episodeData.temperature :
                        '-'}}</span>
                    <mat-icon *ngIf="episodeData.temperature === -1" class="">cached</mat-icon>
                </div>
                <div class="status-content__info">
                    <span class="text-bold">Pulso</span>
                    <span *ngIf="episodeData.heartRate !== -1">{{episodeData.heartRate ? episodeData.heartRate :
                        '-'}}</span>
                    <mat-icon *ngIf="episodeData.heartRate === -1" class="">cached</mat-icon>
                </div>
            </div>
            <div class="display-flex justify-between">
                <div class="pain-content" [ngStyle]="{'background-color': episodeData.painColorCode}">
                    <span class="text-bold">Dor</span>
                    <span>{{episodeData.pain ? episodeData.pain : '-'}}</span>
                </div>
            </div>

        </div>
    </cdk-accordion-item>
</cdk-accordion>