import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-suspend-prescription-schedule-modal',
  templateUrl: './suspend-prescription-schedule-modal.component.html',
  styleUrls: ['./suspend-prescription-schedule-modal.component.css']
})
export class SuspendPrescriptionScheduleModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SuspendPrescriptionScheduleModalComponent>,
    private formBuilder: FormBuilder,
  ) { }

  public model: FormGroup;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      reasonSuspension: ['', [Validators.required]]
    });
  }

  submit() {
    if (this.model.invalid) {
      this.alertService.show('Erro', "O campo 'Motivo de suspensão' é obrigatório", AlertType.error);
      return;
    }

    this.matDialogRef.close({ confirm: true, reasonSuspension: this.model.get('reasonSuspension').value });
  }

  cancel() {
    this.matDialogRef.close({ confirm: false });
  }
}
