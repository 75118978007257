import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { PrescriptionSchedulingService } from 'src/app/shared/services/API/orchestrator-patient/prescription-scheduling.service';
import { VerifyPermissionService } from 'src/app/shared/services/API/user/verify-permission.service';
import { VerifyPermissionRequest } from 'src/app/shared/services/requests/user/verify-permission.request';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { CheckRestrictedOverdueMedicationModalComponent } from '../../../check-restricted-overdue-medication-modal/check-restricted-overdue-medication-modal.component';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { MedicalPrescriptionModalComponent } from 'src/app/observation/pages/bed-management-details/presciption-list/medical-prescription-modal/medical-prescription-modal.component';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { GetListMedicineStruct } from 'src/app/shared/services/structs/pharmacy/get-list-medicine.struct';
import { MedicalPrescriptionCheckStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-prescription-check.struct';
import { VerifyPermissionResponse } from 'src/app/shared/services/responses/user/verify-permission.response';
import { MedicalPrescriptionCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-prescription-check.request';
import { StatusSchedulingEnum } from 'src/app/shared/enum/medical-record/status-scheduling.enum';

@Component({
  selector: 'app-late-pending-prescription-checks',
  templateUrl: './late-pending-prescription-checks.component.html',
  styleUrls: ['./late-pending-prescription-checks.component.css']
})
export class LatePendingPrescriptionChecksComponent implements OnInit {
  @Input() medicalPrescriptionStruct: MedicalPrescriptionStruct;
  @Input() patientName: string;
  @Input() birthDate: Date;
  @Input() idEpisode: number;
  @Input() allergiesToSave: AllergyModel[] = [];
  @Input() listMedicine: GetListMedicineStruct;
  @Input() listCheckedMedication: MedicalPrescriptionCheckRequest[] = [];

  public statusSchedulingEnum: typeof StatusSchedulingEnum = StatusSchedulingEnum;

  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    public prescriptionSchedulingService: PrescriptionSchedulingService,
    public medicalRecordLookupService: MedicalRecordLookupService,
    public verifyPermissionService: VerifyPermissionService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {}

  async onClickInPrescriptionCheck(medicalPrescriptionCheck: MedicalPrescriptionCheckStruct): Promise<void> {
    let verifyPermissionResponse: VerifyPermissionResponse = null;

    let isToVerifyPermission = (medicalPrescriptionCheck.idStatusScheduling !== StatusSchedulingEnum.Suspenso 
                             && medicalPrescriptionCheck.idStatusScheduling !== StatusSchedulingEnum.Concluido);

    if (isToVerifyPermission) {
      verifyPermissionResponse = await this.checkRestrictedOverdueMedication();
      if (!verifyPermissionResponse?.havePermission) 
        return;
    }    

    this.openCheckPrescriptionModal(medicalPrescriptionCheck, verifyPermissionResponse);
  }

  async checkRestrictedOverdueMedication(): Promise<VerifyPermissionResponse> {
    return new Promise((resolve) => {
      if (!this.hasRoleToCheckOverduePrescription()) {
        this.alertService.show('Erro', 'O usuário não possui permissão para liberar a checagem com restrição.', AlertType.error);
        resolve(null);
        return;
      }

      const dialogRef = this.dialog.open(CheckRestrictedOverdueMedicationModalComponent);

      dialogRef.afterClosed().subscribe(async (result) => {
        const hasPopulatedResult = result?.confirm && result?.justification && result?.login && result?.password;

        if (!hasPopulatedResult) {
          resolve(null);
          return;
        }

        const verifyPermissionRequest = new VerifyPermissionRequest();
        verifyPermissionRequest.login = result?.login;
        verifyPermissionRequest.password = result?.password;
        verifyPermissionRequest.permissionEnum = UserRoleEnum.liberador_checar_atrasado_com_restrição;

        const verifyPermissionResponse = await this.verifyPermissionToCheckOverduePrescription(verifyPermissionRequest);

        if (verifyPermissionResponse)
          verifyPermissionResponse.justification = result?.justification;

        resolve(verifyPermissionResponse);
      });
    });
  }

  hasRoleToCheckOverduePrescription(): boolean {
    const listRole = this.utilService.getRoles();
    const isMaster = this.utilService.getToken().isMaster;

    return (listRole.some((x) => x.idRole === UserRoleEnum.checar_atrasado_com_restrição) || isMaster);
  }

  async verifyPermissionToCheckOverduePrescription(request: VerifyPermissionRequest): Promise<VerifyPermissionResponse> {
    return new Promise((resolve) => {
      this.verifyPermissionService.verifyPermission(request).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            resolve(null);
            return;
          }

          if (!response?.havePermission) 
            this.alertService.show('Erro', 'O usuário não possui permissão para liberar a checagem com restrição.', AlertType.error);

          resolve(response);

        },
        error: (error) => {
          this.alertService.show('Erro inesperado', error, AlertType.error);
          resolve(null);
          return;
        }
      });
    });
  }

  openCheckPrescriptionModal(medicalPrescriptionCheck: MedicalPrescriptionCheckStruct, verifyPermissionResponse: VerifyPermissionResponse) {
    const dialogRef = this.dialog.open(MedicalPrescriptionModalComponent, {
      data: {
        prescription: {
          ...this.medicalPrescriptionStruct,
          tempCheck: medicalPrescriptionCheck.idStatusScheduling === StatusSchedulingEnum.Suspenso || medicalPrescriptionCheck.idStatusScheduling === StatusSchedulingEnum.Concluido
        },
        patientName: this.patientName,
        birthDate: this.birthDate,
        actions: true,
        idEpisode: this.idEpisode,
        isMedicatedSolution: false,
        allergiesToSave: this.allergiesToSave,
        listMedicine: this.listMedicine,
        selectedMedicalPrescriptionCheck: medicalPrescriptionCheck,
        useScheduling: true,
        verifyPermissionResponse
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.medicalPrescriptionCheckRequest && result?.medicalPrescriptionCheckRequest instanceof MedicalPrescriptionCheckRequest) {
        this.updateCheckedMedication(result.medicalPrescriptionCheckRequest);
        this.updatePrescriptionCheck(result.medicalPrescriptionCheckRequest, medicalPrescriptionCheck);
      }
    });
  }

  updateCheckedMedication(medicalPrescriptionCheckRequest: MedicalPrescriptionCheckRequest): void {
    const indexCheck = this.listCheckedMedication.findIndex(l => l.idMedicalPrescriptionCheck === medicalPrescriptionCheckRequest.idMedicalPrescriptionCheck);

    if (indexCheck >= 0) {
      this.listCheckedMedication[indexCheck] = medicalPrescriptionCheckRequest;
    } else {
      this.listCheckedMedication.push(medicalPrescriptionCheckRequest);
    }
  }

  updatePrescriptionCheck(medicalPrescriptionCheckRequest: MedicalPrescriptionCheckRequest, medicalPrescriptionCheck: MedicalPrescriptionCheckStruct): void {
    const indexPrescriptionCheck = this.medicalPrescriptionStruct.listMedicalPrescriptionCheck.findIndex(x => x.idMedicalPrescriptionCheck === medicalPrescriptionCheckRequest.idMedicalPrescriptionCheck);

    if (indexPrescriptionCheck >= 0) {
      medicalPrescriptionCheck.idStatusScheduling = medicalPrescriptionCheckRequest.idStatusScheduling;
      medicalPrescriptionCheck.reasonSuspension = medicalPrescriptionCheckRequest.reasonSuspension;
      this.medicalPrescriptionStruct.listMedicalPrescriptionCheck[indexPrescriptionCheck] = medicalPrescriptionCheck;
    }
  }
}
