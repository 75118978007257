<div>
    <div class="header">
        <div class="header-content">
            <div class="info-content">
                <mat-icon aria-hidden="false" aria-label="Info">info</mat-icon>
                <span>Clique no horário para visualizar mais detalhes</span>
            </div>
            <button mat-flat-button type="button" color="primary" class="btn-block" (click)="changeShowSubtitle()">
                <span>Legenda</span>
            </button>
        </div>
        <div class="floating-subtitle"
            [ngClass]="{'floating-subtitle-visible': this.showSubtitle, 'floating-subtitle-hide': !this.showSubtitle}">
            <div>
                <h1>Legenda do Planejamento Terapêutico</h1>
                <div class="status">
                    <ng-container *ngFor="let status of listStatusScheduling">
                        <div class="status-content">
                            <span class="status-subtitle" [style.background-color]="status.color" [style.border-color]="status.color"></span>
                            <span>{{status.name}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="listMedicalPrescriptionStruct && listMedicalPrescriptionStruct.length > 0" class="table-content">
        <table>
            <thead>
                <tr class="header-table">
                    <th>Lista de prescrições solicitadas no episódio</th>
                    <th *ngFor="let datetime of listDateTime" [attr.colspan]="datetime.hour.length" class="date-segment">{{ datetime.date }}</th>
                </tr>
                <tr class="header-table">
                    <th class="header-table header-prescription">
                        <span>Item prescrito</span>
                        <span>Hora:</span>
                    </th>
                    <ng-container *ngFor="let datetime of listDateTime">
                        <th *ngFor="let hour of datetime.hour" class="hour-segment">
                            {{ hour }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody class="table-body-content">
                <tr *ngFor="let item of listMedicalPrescriptionStruct">
                    <td class="prescription-content">
                        <p><b>{{ item.medicineName }}</b></p>
                        <span><b>Quantidade: </b>{{item.quantity}}</span> | <span><b>Via: </b>{{item.usage}}</span> | <span><b>Frequência: </b>{{item.frequencyName}}</span> | <span><b>Diluição: </b>{{item.dilutionName}}</span>
                        <p><b>Observação: </b>{{item.observation}}</p>
                        <p><b>Médico responsável: </b>{{item.userName}}</p>
                        <button *ngIf="isToDisplayLatePendingPrescriptionsCheckButton(item)" mat-flat-button type="button" color="accent" class="btn-block late-button" (click)="openLatePendingPrescriptionChecksModal(item)">
                            <span *ngIf="isLoading == false">
                                <mat-icon aria-hidden="false" aria-label="Info">warning</mat-icon>
                                Concluir checagens atrasadas</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </td>
                    
                    <ng-container *ngFor="let datetime of listDateTime">
                        <td *ngFor="let hour of datetime.hour" class="action-segment">
                            <button type="button" class="scheduling-button" 
                            [ngClass]="item.listMedicalPrescriptionCheck | filterStatusPrescriptionScheduling:this.isTherapeutic:item.idFrequency:'getStatusPrescription':listDateTime:datetime.date:hour"
                            [matTooltip]="getReasonSuspensionByDateTime(item, datetime.date, hour, false)"
                                (click)="openPrescriptionSchedulingModal(item, datetime.date, hour)">
                            </button>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="listMedicatedSolutionPrescription && listMedicatedSolutionPrescription.length > 0" class="table-content">
        <table>
            <thead>
                <tr class="header-table">
                    <th>Lista de prescrições solicitadas no episódio</th>
                    <th *ngFor="let datetime of listDateTime" [attr.colspan]="datetime.hour.length" class="date-segment">{{ datetime.date }}</th>
                </tr>
                <tr class="header-table">
                    <th class="header-table header-prescription">
                        <span>Item prescrito</span>
                        <span>Hora:</span>
                    </th>
                    <ng-container *ngFor="let datetime of listDateTime">
                        <th *ngFor="let hour of datetime.hour" class="hour-segment">
                            {{ hour }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody class="table-body-content">
                <tr *ngFor="let item of listMedicatedSolutionPrescription">
                    <td class="prescription-content">
                        <ng-container *ngFor="let medicine of item.listMedicine">
                            <p><b>{{ medicine.medicineName }}</b></p>
                        </ng-container>
                        <span><b>Quantidade: </b>{{item.quantity}}</span> | <span><b>Via: </b>{{item.usage}}</span> | <span><b>Frequência: </b>{{item.frequencyName}}</span> | <span><b>Diluição: </b>{{item.dilutionName}}</span>
                        <p><b>Observação: </b>{{item.observation}}</p>
                        <p><b>Médico responsável: </b>{{item.userName}}</p>
                        <button *ngIf="isToDisplayLatePendingSolutionCheckButton(item)" mat-flat-button type="button" color="accent" class="btn-block late-button" (click)="openLatePendingSolutionChecksModal(item)">
                            <span *ngIf="isLoading == false">
                                <mat-icon aria-hidden="false" aria-label="Info">warning</mat-icon>
                                Concluir checagens atrasadas</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </td>
                    
                    <ng-container *ngFor="let datetime of listDateTime">
                        <td *ngFor="let hour of datetime.hour" class="action-segment">
                            <button type="button" class="scheduling-button" 
                            [ngClass]="item.listMedicalPrescriptionCheck | filterStatusPrescriptionScheduling:this.isTherapeutic:item.idFrequency:'getStatusPrescription':listDateTime:datetime.date:hour"
                            [matTooltip]="getReasonSuspensionByDateTime(item, datetime.date, hour, false)"
                                (click)="openMedicatedSolutionSchedulingModal(item, datetime.date, hour)">
                            </button>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="listMedicalProcedureStruct && listMedicalProcedureStruct.length > 0" class="table-content">
        <table>
            <thead>
                <tr class="header-table">
                    <th>Lista de procedimentos solicitados no episódio</th>
                    <th *ngFor="let datetime of listDateTime" [attr.colspan]="datetime.hour.length" class="date-segment">{{ datetime.date }}</th>
                </tr>
                <tr class="header-table">
                    <th class="header-table header-prescription">
                        <span>Item prescrito</span>
                        <span>Hora:</span>
                    </th>
                    <ng-container *ngFor="let datetime of listDateTime">
                        <th *ngFor="let hour of datetime.hour" class="hour-segment">
                            {{ hour }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody class="table-body-content">
                <tr *ngFor="let item of listMedicalProcedureStruct">
                    <td class="prescription-content">
                        <p><b>{{ item.procedureName }}</b></p>
                        <p><b>Observação: </b>{{item.observation}}</p>
                        <p><b>Médico responsável: </b>{{item.userName}}</p>
                        <button *ngIf="isToDisplayLatePendingProcedureCheckButton(item)" mat-flat-button type="button" color="accent" class="btn-block late-button" (click)="openLatePendingProcedureChecksModal(item)">
                            <span *ngIf="isLoading == false">
                                <mat-icon aria-hidden="false" aria-label="Info">warning</mat-icon>
                                Concluir checagens atrasadas</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </td>
                    
                    <ng-container *ngFor="let datetime of listDateTime">
                        <td *ngFor="let hour of datetime.hour" class="action-segment">
                            <button type="button" class="scheduling-button" 
                            [ngClass]="item.listMedicalProcedureCheck | filterStatusPrescriptionScheduling:false:null:'getStatusProcedure':listDateTime:datetime.date:hour"
                            [matTooltip]="getReasonSuspensionByDateTime(item, datetime.date, hour, true)"
                                (click)="openProcedureSchedulingModal(item, datetime.date, hour)">
                            </button>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="listSadtGroup && listSadtGroup.length > 0" class="table-content">
        <table>
            <thead>
                <tr class="header-table">
                    <th>Lista de exames solicitados no episódio</th>
                    <th *ngFor="let datetime of listDateTime" [attr.colspan]="datetime.hour.length" class="date-segment">{{ datetime.date }}</th>
                </tr>
                <tr class="header-table">
                    <th class="header-table header-prescription">
                        <span>Item prescrito</span>
                        <span>Hora:</span>
                    </th>
                    <ng-container *ngFor="let datetime of listDateTime">
                        <th *ngFor="let hour of datetime.hour" class="hour-segment">
                            {{ hour }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody class="table-body-content">
                <tr *ngFor="let item of listSadtGroup">
                    <td class="prescription-content">
                        <p><b>{{ item.typeSadtName }}</b></p>
                        <p><b>Observação: </b>{{item.observation}}</p>
                        <p><b>Médico responsável: </b>{{item.userName}}</p>
                    </td>
                    
                    <ng-container *ngFor="let datetime of listDateTime">
                        <td *ngFor="let hour of datetime.hour" class="action-segment">
                            <button type="button" class="scheduling-button" 
                                [ngClass]="item.datetimeCheckEstimative | filterStatusSadtScheduling:item.idStatusScheduling:listDateTime:datetime.date:hour"
                                (click)="openSadtSchedulingModal(item)">
                            </button>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
    
</div>