<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">

            <div class="head-confirm-modal-list">
                <div class="img-head">
                    <img style="width: 5rem;" src="assets/images/exclamation-circle.png" />
                </div>
                <div class="title-modal">
                    <h1>Atenção!</h1>
                </div>
            </div>
            <div>
                <div class="col-12 reason-style">
                    <mat-form-field appearance="outline">
                        <mat-label>Motivo da supensão:</mat-label>
                        <textarea matInput type="text" formControlName="reasonSuspension"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-12 body-modal">
                    <span class="warning-text"> <mat-icon class="warning-icon">warning</mat-icon>
                        Apenas o horário atual será suspenso do planejamento terapêutico do paciente.</span>
                </div>

            </div>
            <div class="btn-container">
                <a mat-flat-button color="primary" class="button-close-modal" (click)="cancel()">VOLTAR</a>
                <button mat-flat-button color="accent" class="button-close-modal" type="submit">CONFIRMAR
                    SUSPENSÃO</button>
            </div>
        </form>
    </div>
</div>