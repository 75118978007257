<div class="prescription-checks-container">
  <button *ngFor="let check of medicalPrescriptionStruct.listMedicalPrescriptionCheck" 
          mat-flat-button color="accent"
          (click)="onClickInPrescriptionCheck(check)" 
          class="btn-block prescription-check-button">
    <span>
      {{ check.idStatusScheduling === statusSchedulingEnum.Suspenso ? '🔴' : check.idStatusScheduling === statusSchedulingEnum.Concluido ? '🟢' : '' }}
    </span>
    Data e hora aprazadada: <b>{{ check.datetimeCheckEstimative | date: 'dd/MM/yyyy' }}, {{ check.datetimeCheckEstimative | date: 'HH:mm' }}</b>
  </button>
</div>
