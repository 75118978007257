<div class="table-content">

    <table class="basic-table desktop">
        <thead class="basic-table__head">
            <tr>
                <th></th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.Episodio)">Episódio</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.Senha)">Senha</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.Entrada)">Entrada</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.Prioridade)">Prioridade</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.LinhaDeCuidado)">Linha de<br> Cuidado</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.Gravidade)">Gravidade</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.NomePaciente)">Nome do <br>Paciente</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.DataNascimento)">Data de nascimento</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.NomeMae)">Nome da mãe</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.LocalizacaoAtual)" matTooltip="Serviço/Setor/Sala">Localização atual</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.TempoEspera)" matTooltip="Horas:Minutos:Segundos">Tempo de<br> espera</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.Situacao)">Situação</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.ProfissionalResponsavel)">Profissional<br>Responsável</th>
                <th *ngIf="this.listColumnToDisplay.includes(columns.Procedimentos)">Procedimentos</th>
                <th></th>
            </tr>
        </thead>
        <tbody class="basic-table__body">
            <tr class="basic-table__body__row" *ngFor="let item of listPatient; let index = index;">
                <td class="truncate">
                    <span class="material-symbols-outlined eye-complete-journey" (click)="openCompleteJourney(item.idEpisode)">visibility</span>
                </td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.Episodio)" class="truncate"><span>{{item.idEpisode}}</span></td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.Senha)" class="truncate">
                    <div class="display-in-ticket"><span>{{item.fullTicket}}</span>
                        <span *ngIf="item.isSchedule" class="display-in-schedule">
                            <mat-icon
                                matTooltip="{{ item.dateStartSchedule | date: 'dd/MM/yyyy' }} - {{ item.dateStartSchedule | date: 'HH:mm' }}h"
                                matTooltipPosition="above" class="reduce-icon-schedule">calendar_month
                            </mat-icon>
                        </span>
                    </div>
                </td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.Entrada)" class="truncate"><span>{{item.ticketDatetimeInclusion | date: 'dd/MM/yyyy
                        HH:mm'}}</span></td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.Prioridade)" class="truncate">
                    <span *ngFor="let priority of item.listPriority">
                        <mat-icon class="icon"
                            *ngIf="item.idPriority !== overEighty && item.idPriority !== overSixty && item.idPriority !== autism">{{priority.icon}}
                        </mat-icon>
                        <img src="assets/images/autism-symbol.png" class="img-adjust" *ngIf="item.idPriority === autism"/>
                        <span class="icon"
                            *ngIf="(item.idPriority === overEighty || item.idPriority === overSixty) && item.idPriority !== autism">{{priority.icon}}</span>
                    </span>
                </td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.LinhaDeCuidado)" class="truncate">
                    <span *ngIf="item.idCareLinePriorityStatus != careLinePriorityStatusDenied && item.careLinePriorityisDefaultIcon"
                                        class="icon material-symbols-outlined"
                                        matTooltip="{{item.careLinePriorityName}}">{{item.careLinePriorityIcon}}</span>
                    <img matTooltip="{{item.careLinePriorityName}}" src="{{item.careLinePriorityIconFilePath}}" class="img-adjust" *ngIf="!item.careLinePriorityisDefaultIcon"/>
                </td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.Gravidade)">
                    <div class="gravity-container">
                        <div *ngIf="item.lastTriage" class="gravity" [style.background-color]="item.lastTriage.gravityColor"
                            matTooltip="{{item.lastTriage.gravityName}}"></div>
                    </div>
                </td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.NomePaciente)" class="truncate"><span>{{item.patientSocialName ? item.patientSocialName :
                        item.patientName}}</span></td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.DataNascimento)" class="truncate"><span>{{item.birthDate | date: 'dd/MM/yyyy'}}</span></td>
    
                <td *ngIf="this.listColumnToDisplay.includes(columns.NomeMae)" class="truncate"><span>{{item.motherName}}</span></td>
    
                <td *ngIf="this.listColumnToDisplay.includes(columns.LocalizacaoAtual)" class="truncate"
                    matTooltip="{{item.serviceName }} {{item.sectorName ? '/ ' + item.sectorName : '' }} {{item.roomName ? '/ ' + item.roomName : ''}}">
                    <span>
                        {{item.locationDisplay}}
                    </span>
                </td>
    
                <td *ngIf="this.listColumnToDisplay.includes(columns.TempoEspera)" class="truncate"
                    matTooltip="Tempo total na instituição: {{item.totalEpisodeTimeHours}}:{{item.totalEpisodeTimeMinutes}}:{{item.totalEpisodeTimeSeconds}}">
                    <span>{{item.waitingTimeHours}}:{{item.waitingTimeMinutes}}:{{item.waitingTimeSeconds}}
                    </span>
                </td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.Situacao)" class="truncate" matTooltip="Número de chamadas: {{item.ticketCallCount}}"
                    [matTooltipClass]="'my-tooltip'">
                    <app-status-bullet [idStatusQueue]="item.idStatusQueue"
                        [statusQueueName]="item.statusQueueName"></app-status-bullet>
                </td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.ProfissionalResponsavel)" class="truncate"><span>{{item.attendantUserName}}</span></td>
                <td *ngIf="this.listColumnToDisplay.includes(columns.Procedimentos)" class="truncate">
                    <span *ngIf="item.hasPrescription" class="material-symbols-outlined"
                        matTooltip="Medicação">vaccines</span>
                    <span *ngIf="item.hasProcedure" class="material-symbols-outlined"
                        matTooltip="Procedimento">healing</span>
                    <span *ngIf="item.hasInternalSadt" class="material-symbols-outlined"
                        matTooltip="Exame interno">biotech</span>
                    <span *ngIf="item.hasExternalSadt" class="material-symbols-outlined"
                        matTooltip="Exame externo">output</span>
                </td>
    
            </tr>
        </tbody>
    </table>
</div>
<cdk-accordion class="accordion mobile">
    <cdk-accordion-item *ngFor="let item of listPatient; let index = index;" #accordionItem="cdkAccordionItem"
        class="accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
        <div [ngClass]="{'accordion-item-header-expanded': accordionItem.expanded}" class="accordion-item-header"
            (click)="accordionItem.toggle()">
            <div class="flex-space-between"><span class="truncate bold padding-right-0">Token</span><span
                    class="truncate">{{item.fullTicket}}</span></div>
            <div class="flex-space-between"><span class="truncate bold padding-right-0">Nome do
                    paciente</span><span class="truncate">{{item.patientSocialName ?
                    item.patientSocialName : item.patientName}}</span></div>
            <div class="flex-space-between"><span class="truncate bold padding-right-0">Prioridade
                    da
                    senha</span>
                <span class="truncate" *ngFor="let priority of item.listPriority">
                    <mat-icon class="icon"
                        *ngIf="priority.idPriority !== overEighty && priority.idPriority !== overSixty">{{priority.icon}}
                    </mat-icon>
                    <img src="assets/images/autism-symbol.png" class="img-adjust" *ngIf="item.idPriority === autism"/>
                    <span class="icon"
                        *ngIf="priority.idPriority === overEighty || priority.idPriority === overSixty">{{priority.icon}}</span>
                </span>
            </div>
            <div class="flex-space-between">
                <span class="truncate bold padding-right-0">Linha de Cuidado</span>
                <span *ngIf="item.careLinePriorityisDefaultIcon" class="material-symbols-outlined"
                    matTooltip="{{item.careLinePriorityName}}">{{item.careLinePriorityIcon}}</span>
                <img matTooltip="{{item.careLinePriorityName}}" src="{{item.careLinePriorityIconFilePath}}" class="img-adjust" *ngIf="!item.careLinePriorityisDefaultIcon"/>    
            </div>
            <div class="flex-space-between"><span class="truncate bold padding-right-0">Gravidade</span>
                <span class="truncate">
                    <div class="gravity-container">
                        <div *ngIf="item.listTriage && item.listTriage.length > 0" class="gravity"
                            [style.background-color]="item.listTriage[0].gravityColorCode"></div>
                    </div>
                </span>
            </div>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
            <div class="feature-content">
                <div class="padding-x">
                    <div class="truncate"><span class="material-symbols-outlined eye-complete-journey">visibility</span></div>
                    <div class="flex-space-between padding-y"><span
                            class="truncate bold padding-right-0">Episódio</span> <span
                            class="truncate">{{item.idEpisode}}</span></div>
                    <div class="flex-space-between padding-y"><span class="truncate bold padding-right-0">Responsável
                            pelo
                            atendimento</span>
                        <span class="truncate">{{item.attendantUserName}}</span>
                    </div>
                    <div class="flex-space-between padding-y"><span class="truncate bold padding-right-0">Nº de chamadas
                            da senha</span>
                        <span class="truncate">{{item.ticketCallCount}}</span>
                    </div>
                    <div class="flex-space-between padding-y"><span class="truncate bold padding-right-0">Retirada da
                            senha</span> <span class="truncate">{{item.ticketDatetimeInclusion}}</span></div>
                    <div class="flex-space-between padding-y"><span class="truncate bold padding-right-0">Tempo de
                            espera</span> <span class="truncate">{{item.waitingTime}}</span></div>
                    <div class="flex-space-between padding-y"><span
                            class="truncate bold padding-right-0">Situação</span>
                        <span class="truncate">
                            <app-status-bullet [idStatusQueue]="item.idStatusQueue"
                                [statusQueueName]="item.statusQueueName">
                            </app-status-bullet>
                        </span>
                    </div>
                    <div class="flex-space-between padding-y">
                        <span class="truncate bold padding-right-0">Profissional Responsável</span>
                        <span class="truncate">{{item.attendantUserName}}</span>
                    </div>
                    <div class="flex-space-between padding-y">
                        <span class="truncate bold padding-right-0">Procedimentos</span>
                        <span *ngIf="hasPrescription" class="material-symbols-outlined"
                            matTooltip="Medicação">vaccines</span>
                        <span *ngIf="hasProcedure" class="material-symbols-outlined"
                            matTooltip="Procedimento">healing</span>
                        <span *ngIf="hasXRay" class="material-symbols-outlined"
                            matTooltip="Raio-X">sensor_occupied</span>
                        <span *ngIf="hasSadt" class="material-symbols-outlined" matTooltip="Exame">biotech</span>
                    </div>
                </div>
            </div>
        </div>
    </cdk-accordion-item>
</cdk-accordion>