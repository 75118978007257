import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListSectorResponse } from '../../responses/flow/list-sector.response';
import { LocationByModuleResponse } from '../../responses/flow/location-by-module.response';

@Injectable({
  providedIn: 'root'
})
export class LocationByModuleService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public listRoomByModule(idModule: number, idFunctionalityGroup: number = null): Observable<LocationByModuleResponse> {

    let uri = `LocationByModule/idModule/${idModule}`;
    if (idFunctionalityGroup != null) {
      uri += `?idFunctionalityGroup=${idFunctionalityGroup}`;
    }

    return this.httpClient.get<LocationByModuleResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listRoomByFunctionalityGroup(idFunctionalityGroup: number): Observable<LocationByModuleResponse> {

    let uri = `LocationByModule/idFunctionalityGroup/${idFunctionalityGroup}`

    return this.httpClient.get<LocationByModuleResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }

  public listSectorByModule(jsonListIdModule: string, jsonListIdFunctionalityGroup?: string): Observable<ListSectorResponse> {
    let uri = 'LocationByModule/getAll?'

    uri = uri + `jsonListIdModule=${jsonListIdModule}&`

    if (jsonListIdFunctionalityGroup)
      uri = uri + `jsonListIdFunctionalityGroup=${jsonListIdFunctionalityGroup}&`

    return this.httpClient.get<ListSectorResponse>(environment.urlApiFlow + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}