import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import { AuthComponent } from './auth/pages/auth/auth.component';


const routes: Routes = [
  { path: '', component: AuthComponent, pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'master',
    loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  { path: 'suportetolife', redirectTo: 'client/suportetolife', pathMatch: 'full' },
  {
    path: 'front-desk',
    loadChildren: () => import('./front-desk/front-desk.module').then(m => m.FrontDeskModule)
  },
  {
    path: 'generic-call',
    loadChildren: () => import('./generic-call/generic-call.module').then(m => m.GenericCallModule)
  },
  {
    path: 'medic',
    loadChildren: () => import('./medic/medic.module').then(m => m.MedicModule)
  },
  {
    path: 'sadt',
    loadChildren: () => import('./sadt/sadt.module').then(m => m.SADTModule)
  },
  {
    path: 'audit',
    loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule)
  },
  {
    path: 'classification',
    loadChildren: () => import('./classification/classification.module').then(m => m.ClassificationModule)
  },
  {
    path: 'integration',
    loadChildren: () => import('./integration/integration.module').then(m => m.IntegrationModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule)
  },
  {
    path: 'observation',
    loadChildren: () => import('./observation/observation.module').then(m => m.ObservationModule)
  },
  {
    path: 'patient-history',
    loadChildren: () => import('./patient-history/patient-history.module').then(m => m.PatientHistoryModule)
  },
  {
    path: 'form',
    loadChildren: () => import('./form/form.module').then(m => m.FormModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'remote-monitoring',
    loadChildren: () => import('./remote-monitoring/remote-monitoring.module').then(m => m.RemoteMonitoringModule)
  },
  {
    path: 'bi',
    loadChildren: () => import('./business-intelligence/business-intelligence.module').then(m => m.BusinessIntelligenceModule)
  },
  {
    path: 'pharmacy',
    loadChildren: () => import('./pharmacy/pharmacy.module').then(m => m.PharmacyModule)
  },
  {
    path: 'flow-management',
    loadChildren: () => import('./flow-management/flow-management.module').then(m => m.FlowManagementModule)
  },
  {
    path: 'multi-professional',
    loadChildren: () => import('./multi-professional/multi-professional.module').then(m => m.MultiProfessionalModule)
  },
  {
    path: 'ubs-monitoring',
    loadChildren: () => import('./ubs-monitoring/ubs-monitoring.module').then(m => m.UbsMonitoringModule)
  },
  {
    path: 'digital-prompt-service',
    loadChildren: () => import('./digital-prompt-service/digital-prompt.module').then(m => m.DigitalPromptModule),
  },
  {
    path: 'care-line-monitoring',
    loadChildren: () => import('./care-line-monitoring/care-line-monitoring.module').then(m => m.CareLineMonitoringModule)
  },
  {
    path: 'therapeutic-planning',
    loadChildren: () => import('./therapeutic-planning/therapeutic-planning.module').then(m => m.TherapeuticPlanningModule)
  },
  
  { path: '**', redirectTo: ''},//sempre deixar após todas as rotas
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  }),
  WebBluetoothModule.forRoot({
    enableTracing: true // or false, this will enable logs in the browser's console
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
