import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-check-restricted-overdue-medication-modal',
  templateUrl: './check-restricted-overdue-medication-modal.component.html',
  styleUrls: ['./check-restricted-overdue-medication-modal.component.css']
})

export class CheckRestrictedOverdueMedicationModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<CheckRestrictedOverdueMedicationModalComponent>,
    private formBuilder: FormBuilder,) { }

  public model: FormGroup;
  public hide: boolean = false;
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
      justification: ['', [Validators.required]],
    });
  }

  submit() {
    if (this.model.invalid || this.isLoading) {
      this.alertService.show('Erro', "É necessário preencher todos os campos obrigatórios.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.matDialogRef.close({ confirm: true, login: this.model.get('login').value, password: this.model.get('password').value, justification: this.model.get('justification').value });
  }

  close(){
    this.matDialogRef.close({confirm: false});
  }
}