import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsClassificationService } from 'src/app/classification/utils.service';
import { DiscriminatorTypeEnum } from 'src/app/shared/enum/discriminator-type.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { DiscriminatorModel } from 'src/app/shared/services/models/risk-classification/discriminator.model';
import { DeviceTypeSectorResponse } from 'src/app/shared/services/responses/risk-classification/device-type-sector.response';
import { UtilsMeasurerService } from '../../utils-measurer.service';

@Component({
  selector: 'app-glucose-modal',
  templateUrl: './glucose-modal.component.html',
  styleUrls: ['./glucose-modal.component.css']
})
export class GlucoseModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<GlucoseModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private utilService: UtilsClassificationService,
    private maskService: MaskService,
    public measurerService: UtilsMeasurerService) { }

  public model: UntypedFormGroup;
  public discriminator: DiscriminatorModel;
  public masks: Masks;
  public initValue: any = null;
  public discriminatorType: DiscriminatorTypeEnum = DiscriminatorTypeEnum.Glicemia;
  public autisticChecked: boolean = false;
  public setUppercaseText: boolean = false;
  public isMasked;
  ngOnInit(): void {
    let deviceTypeSectorResponse: DeviceTypeSectorResponse = this.utilService.getDevicetypeBySector()
    if (deviceTypeSectorResponse && deviceTypeSectorResponse.deviceTypeSector) {
      if (deviceTypeSectorResponse.deviceTypeSector.setUppercaseText)
        this.setUppercaseText = deviceTypeSectorResponse.deviceTypeSector.setUppercaseText;
    }

    this.measurerService.setModal(this.discriminatorType);
    this.masks = this.maskService.getMasks();

    this.discriminator = this.data.discriminator;
    this.initValue = this.data.discriminatorsValues[this.discriminatorType];

    let hi = false;
    let lo = false;
    if (this.data.state) {
      hi = this.data.discriminatorsValues[this.discriminatorType] == 'Hi';
      lo = this.data.discriminatorsValues[this.discriminatorType] == 'Lo';
      this.isMasked = true;
    } else {
      this.isMasked = false;
    }

    this.model = this.formBuilder.group({
      glucose: [{ value: this.data.discriminatorsValues[this.discriminatorType], disabled: hi || lo }, Validators.required],
      discriminatorSelectionMotive: [],
      priorityAutistic: [false],
      high: [hi],
      low: [lo],
    });
  }

  changePriorityAutistic(event: any) {
    this.model.get('glucose').setValue(null);
    this.model.get('high').setValue(null);
    this.model.get('low').setValue(null);
    this.autisticChecked = event.checked
    if (event.checked) {
      this.model.get('discriminatorSelectionMotive').setValidators([Validators.required]);
      this.model.get('glucose').disable();
      this.model.get('high').disable();
      this.model.get('low').disable();
    } else {
      this.model.get('discriminatorSelectionMotive').setValidators(Validators.nullValidator);
      this.model.get('glucose').enable();
      this.model.get('high').enable();
      this.model.get('low').enable();
    }
    this.model.get('discriminatorSelectionMotive').updateValueAndValidity();
  }

  glucoseOOR(value: string) {
    if (value == 'Hi' && this.model.get('high').value) {
      this.isMasked = false;
      this.model.get('low').setValue(null);
      this.model.get('glucose').disable();
      this.model.get('glucose').setValue(value);
    } else if (value == 'Lo' && this.model.get('low').value) {
      this.isMasked = false;
      this.model.get('high').setValue(null);
      this.model.get('glucose').disable();
      this.model.get('glucose').setValue(value);
    } else {
      this.isMasked = true;
      this.model.get('glucose').enable();
      this.model.get('glucose').setValue(null);
    }
  }
  close() {
    this.measurerService.setModal(null);
    this.matDialogRef.close();
  }

  submit() {
    if (this.data.discriminatorsValues[this.discriminatorType] && (this.initValue == this.model.get("glucose").value || (this.initValue != this.data.discriminatorsValues[this.discriminatorType] && !this.model.get("glucose").value))) {
      this.model.get("glucose").setValue(this.data.discriminatorsValues[this.discriminatorType]);
    }
    if (this.model.invalid) {
      if (this.model.get('priorityAutistic').value) {
        this.alertService.show('Erro', "Preencha o motivo!", AlertType.error);
      } else {
        this.alertService.show('Erro', "Preencha o campo de Glicose!", AlertType.error);
      }
      return;
    }
    if (this.model.get('priorityAutistic').value) {
      this.matDialogRef.close({ value: null, select: true, motive: this.model.get('discriminatorSelectionMotive').value });
    } else if (this.model.get('low').value || this.model.get('high').value) {
      let state = this.model.get('low').value ? 'Lo' : 'Hi';
      let value = this.model.get('low').value ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
      let select = this.utilService.verifySelectGeneric(this.discriminator.minValueAllowed, this.discriminator.maxValueAllowed, value);
      this.matDialogRef.close({ value: state, select, state: true });
    } else {
      let value = this.model.get("glucose").value.replace("_", "");
      let select = this.utilService.verifySelectGeneric(this.discriminator.minValueAllowed, this.discriminator.maxValueAllowed, value);
      this.measurerService.setModal(null);
      this.matDialogRef.close({ value, select });
    }
  }
}

