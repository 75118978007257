<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-confirm-modal-list" *ngIf="data.hasImage">
            <div class="img-head">
                <img style="width: 5rem;" src="assets/images/exclamation-circle.png" />
            </div>
            <div class="title-modal">
                <h1>{{title}}</h1>
            </div>
            <div *ngIf="!highlightedText" class="body-modal">
                {{description}}
            </div>
            <div *ngIf="highlightedText" class="body-modal">
                {{ description }} <b>{{ highlightedText }}</b>.
                <p *ngIf="description2">{{ description2 }}</p>
            </div>
        </div>
        <div *ngIf="!data.hasImage">
            <div class="title-modal">
                <h1>{{title}}</h1>
            </div>
            <div class="body-modal">
                {{description}}
            </div>
        </div>
        <div *ngIf="!data.isTwoButtonsModal && !data.hasImage" class="btn-container">
            <button mat-flat-button color="primary" class="button-close-modal"
                (click)="close()">{{textButtonCancel}}</button>
        </div>
        <div *ngIf="!data.isTwoButtonsModal && data.hasImage" class="btn-container">
            <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" class="button-close-modal-other"
                        (click)="close()">{{textButtonCancel}}</button>
                </div>
            </div>
        </div>
        <div *ngIf="this.data.isTwoButtonsModal" class="btn-container">
            <button mat-flat-button color="accent" class="button-close-modal"
                (click)="confirm()">{{this.textButtonConfirm}}</button>
            <button mat-flat-button color="primary" class="button-close-modal"
                (click)="cancel()">{{this.textButtonCancel}}</button>
        </div>
    </div>
</div>