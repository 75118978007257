<div class="mat-dialog-content">
  <div mat-dialog-title>
    <div class="alert-title">
      <h1>Atenção</h1>
    </div>
    <div class="img-head">
      <img style="width: 5rem;" src="assets/images/exclamation-circle.png" />
    </div>
    <div class="alert-text">
        <p>Existem checagens atrasadas para este item prescrito.</p>
        <p><i>Clique no horário aprazado para resolver a pendência</i></p>
    </div>
  </div>
  
  <div mat-dialog-content>
    <app-late-pending-prescription-checks 
      *ngIf="typeOfPendingCheck === 'prescription'"
      [medicalPrescriptionStruct]="medicalPrescriptionStruct"
      [patientName]="patientName"
      [birthDate]="birthDate"
      [idEpisode]="idEpisode"
      [allergiesToSave]="allergiesToSave"
      [listMedicine]="listMedicine"
      [listCheckedMedication]="listCheckedMedication">
    </app-late-pending-prescription-checks>
  
    <app-late-pending-solution-checks 
      *ngIf="typeOfPendingCheck === 'solution'"
      [medicatedSolutionStruct]="medicatedSolutionStruct"
      [patientName]="patientName"
      [birthDate]="birthDate"
      [idEpisode]="idEpisode"
      [allergiesToSave]="allergiesToSave"
      [listMedicine]="listMedicine"
      [listCheckedMedication]="listCheckedMedication">
    </app-late-pending-solution-checks>
  
    <app-late-pending-procedure-checks 
      *ngIf="typeOfPendingCheck === 'procedure'"
      [medicalProcedureStruct]="medicalProcedureStruct"
      [patientName]="patientName"
      [birthDate]="birthDate"
      [idEpisode]="idEpisode"
      [listCheckedProcedure]="listCheckedProcedure">
    </app-late-pending-procedure-checks>
  </div>
  
  <div mat-dialog-actions>
    <button mat-flat-button color="primary" class="btn-block"  (click)="confirmEdit()">Confirmar</button>
    <button mat-flat-button color="accent" class="btn-block"  (click)="cancelEdit()">Cancelar</button>
  </div>
  
</div>
