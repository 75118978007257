<div mat-dialog-content>
    <app-loading-list *ngIf="isFirstLoading"></app-loading-list>
    <div *ngIf="!isFirstLoading" class="mat-dialog-content">
        <!-- <div class="title-header">
            <div class="row">
                
                <div class="col-6 col-sm-6 col-md-6">
                    <mat-slide-toggle class="indigent-checkbox" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="this.isUnidentifiedPatient" (change)="changeIndigent($event)">Paciente não
                        identificado</mat-slide-toggle>
                </div>
            </div>
        </div> -->
        <!-- <mat-form-field appearance="outline">
            <mat-label>Prioridade</mat-label>
            <mat-select formControlName="listIdPriority" multiple #prioritySelect
                (selectionChange)="validatePriorities($event)">
                <mat-option *ngFor="let item of listOfPriorities" [value]="item.idPriority"
                    [disabled]="disallowedIdPriorities.includes(item.idPriority)">{{item.priorityName}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

        <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-6" *ngIf="this.isUnidentifiedPatient">
            <mat-form-field appearance="outline">
                <mat-label>Descrição do paciente não identificado</mat-label>
                <textarea matInput type="text" formControlName="unidentifiedPatientDescription"></textarea>
            </mat-form-field>
        </div> -->
        <div *ngIf="this.isUnidentifiedPatient && this.episodeStruct">
            <div class="section-container">
                <div class="title-header">
                    <h1>Dados Pessoais</h1>
                </div>
                <div class="priorities" *ngIf="listPriorityEpisode && listPriorityEpisode.length > 0">
                    <span class="label-priority"><b>Prioridade: </b></span>
                    <span class="icon" *ngFor="let priority of this.listPriorityEpisode">
                        <mat-icon 
                            *ngIf="priority.idPriority !== this.overEightyYearsEnum && priority.idPriority !== this.overSixtyYearsEnum && priority.idPriority !== this.autismEnum">{{priority.icon}}</mat-icon>
                        <img src="assets/images/autism-symbol.png" class="img-adjust"
                            *ngIf="priority.idPriority === this.autismEnum" />
                        <span
                            *ngIf="priority.idPriority === this.overEightyYearsEnum || priority.idPriority === this.overSixtyYearsEnum">{{priority.icon}}</span>
                    </span>
                </div>
                <div *ngIf="episodeStruct.unidentifiedPatientDescription">
                    <span><b>Descrição do paciente não identificado: </b></span>
                    <span>{{episodeStruct.unidentifiedPatientDescription}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="!this.isUnidentifiedPatient && this.patient">
            <div class="section-container">
                <div class="title-header">
                    <h1>Dados Pessoais</h1>
                </div>
                <div class="row section-personal-data">
                    <div *ngIf="patient.socialName">
                        <span><b>Nome Social: </b></span>
                        <span>{{patient.socialName}}</span>
                    </div>
                    <div *ngIf="patient.patientName">
                        <span><b>Nome: </b></span>
                        <span>{{patient.patientName}}</span>
                    </div>
                    <div *ngIf="patient.birthDate">
                        <span><b>Data de Nascimento: </b></span>
                        <span>{{patient.birthDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div *ngIf="patient.birthDate">
                        <span><b>Idade: </b></span>
                        <span>{{this.patientAge}}</span>
                    </div>
                    <!-- Antigo Gênero -->
                    <div *ngIf="patient.genderName">
                        <span><b>Sexo: </b></span>
                        <span>{{patient.genderName}}</span>
                    </div>
                    <div *ngIf="patient.genderIdentityName">
                        <span><b>Gênero: </b></span>
                        <span>{{patient.genderIdentityName}}</span>
                    </div>
                    <div *ngIf="patient.cpf">
                        <span><b>CPF: </b></span>
                        <span>{{patient.cpf}}</span>
                    </div>
                    <div *ngIf="patient.cns">
                        <span><b>CNS: </b></span>
                        <span>{{patient.cns}}</span>
                    </div>
                    <div *ngIf="patient.phone1">
                        <span><b>Telefone 1: </b></span>
                        <span>{{patient.phone1}}</span>
                    </div>
                    <div *ngIf="patient.phone2">
                        <span><b>Telefone 2: </b></span>
                        <span>{{patient.phone2}}</span>
                    </div>
                    <div *ngIf="patient.raceName">
                        <span><b>Raça: </b></span>
                        <span>{{patient.raceName}}</span>
                    </div>
                    <div *ngIf="patient.educationName">
                        <span><b>Escolaridade: </b></span>
                        <span>{{patient.educationName}}</span>
                    </div>
                    <div *ngIf="patient.motherName">
                        <span><b>Nome da Mãe: </b></span>
                        <span>{{patient.motherName}}</span>
                    </div>
                    <div *ngIf="patient.fatherName">
                        <span><b>Nome do Pai: </b></span>
                        <span>{{patient.fatherName}}</span>
                    </div>
                    <div *ngIf="patient.profession">
                        <span><b>Profissão: </b></span>
                        <span>{{patient.profession}}</span>
                    </div>
                    <div *ngIf="patient.rg">
                        <span><b>RG: </b></span>
                        <span>{{patient.rg}}</span>
                    </div>
                    <div *ngIf="patient.expeditionIssuer">
                        <span><b>Órgão Expeditor: </b></span>
                        <span>{{patient.expeditionIssuer}}</span>
                    </div>
                    <div *ngIf="patient.expeditionDate">
                        <span><b>Data de Expedição: </b></span>
                        <span>{{patient.expeditionDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div *ngIf="patient.maritalStatusName">
                        <span><b>Estado Civil: </b></span>
                        <span>{{patient.maritalStatusName}}</span>
                    </div>
                    <div class="priorities" *ngIf="listPriorityEpisode && listPriorityEpisode.length > 0">
                        <span class="label-priority"><b>Prioridade: </b></span>
                        <span class="icon" *ngFor="let priority of this.listPriorityEpisode">
                            <mat-icon 
                                *ngIf="priority.idPriority !== this.overEightyYearsEnum && priority.idPriority !== this.overSixtyYearsEnum && priority.idPriority !== this.autismEnum">{{priority.icon}}</mat-icon>
                            <img src="assets/images/autism-symbol.png" class="img-adjust"
                                *ngIf="priority.idPriority === this.autismEnum" />
                            <span
                                *ngIf="priority.idPriority === this.overEightyYearsEnum || priority.idPriority === this.overSixtyYearsEnum">{{priority.icon}}</span>
                        </span>
                    </div>
                    <div *ngIf="patient.birthCountry">
                        <span><b>Nacionalidade: </b></span>
                        <span>{{patient.birthCountry}}</span>
                    </div>
                    <div *ngIf="patient.birthState">
                        <span><b>Estado de Origem: </b></span>
                        <span>{{patient.birthState}}</span>
                    </div>
                    <div *ngIf="patient.birthCity">
                        <span><b>Cidade de Origem: </b></span>
                        <span>{{patient.birthCity}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="this.showSectionAddress" class="section-container">
                <div class="title-header">
                    <h1>Endereço</h1>
                </div>
                <div class="row section-address">
                    <div *ngIf="patient.zipCode">
                        <span><b>CEP: </b></span>
                        <span>{{patient.zipCode}}</span>
                    </div>
                    <div *ngIf="patient.street">
                        <span><b>Logradouro: </b></span>
                        <span>{{patient.street}}</span>
                    </div>
                    <div *ngIf="patient.houseNumber">
                        <span><b>Número: </b></span>
                        <span>{{patient.houseNumber}}</span>
                    </div>
                    <div *ngIf="patient.apartmentNumber">
                        <span><b>Complemento: </b></span>
                        <span>{{patient.apartmentNumber}}</span>
                    </div>
                    <div *ngIf="patient.neighborhood">
                        <span><b>Bairro: </b></span>
                        <span>{{patient.neighborhood}}</span>
                    </div>
                    <div *ngIf="patient.city">
                        <span><b>Cidade: </b></span>
                        <span>{{patient.city}}</span>
                    </div>
                    <div *ngIf="patient.state">
                        <span><b>Estado: </b></span>
                        <span>{{patient.state}}</span>
                    </div>
                    <div *ngIf="patient.country">
                        <span><b>País: </b></span>
                        <span>{{patient.country}}</span>
                    </div>
                </div>
            </div>            
            <div *ngIf="this.showSectionOrigin" class="section-container">
                <div class="title-header">
                    <h1>Procedência</h1>
                </div>
                <div class="row section-origin">
                    <div *ngIf="patient.originName">
                        <span><b>Origem: </b></span>
                        <span>{{patient.originName}}</span>
                    </div>
                    <div *ngIf="patient.arrivalTypeName">
                        <span><b>Tipo de chegada: </b></span>
                        <span>{{patient.arrivalTypeName}}</span>
                    </div>
                    <div *ngIf="this.patient.listPatientNecessities && this.patient.listPatientNecessities.length > 0">
                        <span><b>Necessidades do paciente: </b></span>
                        <span>{{this.patientNecessities}}</span>
                    </div>
                    <div *ngIf="patient.observation">
                        <span><b>Observação: </b></span>
                        <span>{{patient.observation}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="this.showSectionCompanion" class="section-container">
                <div class="title-header">
                    <h1>Acompanhante</h1>
                </div>
                <div class="row section-companion">
                    <div *ngIf="patient.companionName">
                        <span><b>Nome: </b></span>
                        <span>{{patient.companionName}}</span>
                    </div>
                    <div *ngIf="patient.companionPhone">
                        <span><b>Telefone: </b></span>
                        <span>{{patient.companionPhone}}</span>
                    </div>
                    <div *ngIf="patient.companionCpf">
                        <span><b>CPF: </b></span>
                        <span>{{patient.companionCpf}}</span>
                    </div>
                    <div *ngIf="patient.companionParentage">
                        <span><b>Parentesco: </b></span>
                        <span>{{patient.companionParentage}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="this.lastTriage" class="section-container">
                <div class="title-header">
                    <h1>Última Triagem</h1>
                </div>
                <div class="row section-origin">
                    <app-triage-resume [triage]="lastTriage" [isHistorical]="true"></app-triage-resume>
                </div>
            </div>
            <div *ngIf="this.listValuePatientMetaData && this.listValuePatientMetaData.length > 0" class="section-container">
                <div class="title-header">
                    <h1>Dados Adicionais</h1>
                </div>
                <div class="row">
                    <div *ngFor="let item of listValuePatientMetaData">
                        <span><b>{{item.name}}: </b></span>
                        <span *ngIf="item.id != metaDataTypeEnum.Data">{{item.value}}</span>
                        <span *ngIf="item.id == metaDataTypeEnum.Data">{{item.value | date: 'dd/MM/yyyy'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-footer class="footer-modal">
    <div *ngIf="isLoading == false" class="col-12 col-md-4">
        <button mat-flat-button type="button" color="accent" class="btn-block" (click)="closeModal()">
            <span>Fechar</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
    </div>
</div>