import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdditionalItemForPrescriptionReleaseFormStruct } from '../../services/structs/pharmacy/additional-item-for-prescription-release-form.struct';
import { AdditionalMedicineForPrescriptionReleaseFormStruct } from '../../services/structs/pharmacy/additional-medicine-for-prescription-release-form.struct';

@Component({
  selector: 'app-table-additional-items-prescription-release',
  templateUrl: './table-additional-items-prescription-release.component.html',
  styleUrls: ['./table-additional-items-prescription-release.component.css']
})
export class TableAdditionalItemsPrescriptionReleaseComponent implements OnInit {
  constructor() { }

  @Input() listAdditionalItems: AdditionalItemForPrescriptionReleaseFormStruct[] = [];
  @Input() listAdditionalMedicines: AdditionalMedicineForPrescriptionReleaseFormStruct[] = [];
  @Input() list: any[] = [];
  @Output() actionClicked = new EventEmitter<any>();
  @Output() actionEditClicked = new EventEmitter<any>();

  ngOnInit(): void {
  }

  onViewClick(item: any) {
    this.actionClicked.emit(item); 
  }

  onEditClick(item: any) {
    this.actionEditClicked.emit(item); 
  }
}