<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <span class="pin-intro">Digite seus dados de identificação</span>
            <p>Para checar uma prescrição atrasada, é necessário um profissional autorizado</p>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Matrícula ou CPF</mat-label>
                        <input matInput type="text" formControlName="login" required>
                        <mat-error *ngIf="model.get('login').invalid">Informe a Matricula ou CPF</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="col-md-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Senha</mat-label>
                            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' :
                                'visibility_off'}}</mat-icon>
                            <input matInput #password type="password" [type]="hide ? 'text' : 'password'"
                                formControlName="password" required>
                            <mat-error *ngIf="model.get('password').invalid">Informe a senha</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Justificativa</mat-label>
                        <textarea matInput formControlName="justification"></textarea>
                        <mat-error *ngIf="model.get('justification').invalid">Informe a justificativa</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="footer-buttons">
                <button  mat-flat-button color="accent" class="btn-block button-back" type="button" (click)="close()">
                    <span *ngIf="isLoading == false">Voltar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button mat-flat-button color="primary" class="btn-block button-confirm" type="submit">
                    <span *ngIf="isLoading == false">Confirmar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>