import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MenuComponent } from './components/menu/menu.component';
import { UserInfosComponent } from './components/menu/user-infos/user-infos.component';
import { ModuleInfosComponent } from './components/menu/module-infos/module-infos.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { FilterSelectModalComponent } from './components/filter-select/filter-select-modal/filter-select-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthRoutingModule } from '../auth/auth-routing.module';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { LoadingListComponent } from './components/loading-list/loading-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { SecundaryHeaderComponent } from './components/secundary-header/secundary-header.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LoadingProgressBarComponent } from './components/loading-progress-bar/loading-progress-bar.component';
import { SelectComponent } from './components/select/select.component';
import { MatSelectModule } from '@angular/material/select';
import { AttendanceStatusComponent } from './components/attendance-status/attendance-status.component';
import { GenericIndicatorDisplayComponent } from './components/generic-indicator-display/generic-indicator-display.component';
import { SelectRoomModalComponent } from './components/select-room-modal/select-room-modal.component';
import { StatusBulletComponent } from './components/status-bullet/status-bullet.component';
import { SubMenuComponent } from './components/menu/sub-menu/sub-menu.component';
import { EvadePatientModalComponent } from './components/evade-patient-modal/evade-patient-modal.component';
import { NotSeletedRoomComponent } from './components/not-seleted-room/not-seleted-room.component';
import { VitalHistoryModalComponent } from './components/secundary-header/vital-history-modal/vital-history-modal.component';
import { TagComponent } from './components/tag/tag.component';
import { TriageResumeComponent } from './components/triage-resume/triage-resume.component';
import { Select2Component } from './components/select/select2/select2.component';
import { NetworkTestModalComponent } from './components/menu/network-test-modal/network-test-modal.component';
import { PatientStatusTagComponent } from './components/patient-status-tag/patient-status-tag.component';
import { ObservationForwardModalComponent } from './components/observation-forward-modal/observation-forward-modal.component';
import { ObservationForwardConfirmModalComponent } from './components/observation-forward-modal/observation-forward-confirm-modal/observation-forward-confirm-modal.component';
import { ObservationForwardBedConfirmModalComponent } from './components/observation-forward-modal/observation-forward-bed-confirm-modal/observation-forward-bed-confirm-modal.component';
import { PrescriptionListComponent } from './components/prescription-list/prescription-list.component';
import { MedicalPrescriptionModalComponent } from './components/prescription-list/medical-prescription-modal/medical-prescription-modal.component';
import { GravityTagComponent } from './components/gravity-tag/gravity-tag.component';
import { UpgradeModalComponent } from './components/upgrade-modal/upgrade-modal.component';
import { ExternalPrescriptionModalComponent } from './components/external-prescription-modal/external-prescription-modal.component';
import { SadtModalComponent } from './components/sadt-modal/sadt-modal.component';
import { ReportSelectModalComponent } from './components/report-select-modal/report-select-modal.component';
import { TwilioVideoComponent } from './components/twilio-video/twilio-video.component';
import { CameraComponent } from './components/twilio-video/pages/camera/camera.component';
import { ParticipantComponent } from './components/twilio-video/pages/participant/participant.component';
import { SettingsComponent } from './components/twilio-video/pages/settings/settings.component';
import { DeviceSelectComponent } from './components/twilio-video/pages/settings/device-select/device-select.component';
import { FlowSequenceModalComponent } from './components/flow-sequence-modal/flow-sequence-modal.component';
import { NotificationComponent } from './components/menu/notification/notification.component';
import { NotificationDisplayModalComponent } from './components/menu/notification/notification-display-modal/notification-display-modal.component';
import { RankInfosComponent } from './components/menu/rank-infos/rank-infos.component';
import { withoutPermissionEvadeModal } from './components/without-permission-evade-modal/without-permission-evade-modal.component';
import { RoomOccupiedConfirmModalComponent } from './components/room-occupied-confirm-modal/room-occupied-confirm-modal.component';
import { RoomOccupiedModalComponent } from './components/room-occupied-modal/room-occupied-modal.component';
import { RoomOccupiedRemoveModalComponent } from './components/room-occupied-remove-modal/room-occupied-remove-modal.component';
import { CallPatientModalComponent } from './components/call-patient-modal/call-patient-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { PatientListReprintReportModalComponent } from './components/patient-list-reprint-report-modal/patient-list-reprint-report-modal.component';
import { AllergyTagComponent } from './components/allergy-tag/allergy-tag.component';
import { RemoveAllergyModalComponent } from './components/allergy-tag/remove-allergy-modal/remove-allergy-modal.component';
import { ModuleMenuComponent } from './components/menu/module-menu/module-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SelfTriageResumeModalComponent } from './components/self-triage-resume-modal/self-triage-resume-modal.component';
import { PinCertificateModalComponent } from './components/pin-certificate-modal/pin-certificate-modal.component';
import { IframePreviewModalComponent } from './components/iframe-preview-modal/iframe-preview-modal.component';
import { CareLineModalComponent } from './components/care-line-modal/care-line-modal.component';
import { ModuleFunctionalityComponent } from './components/module-functionality/module-functionality.component';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { ValidateCareLinePriorityModalComponent } from './components/validate-care-line-priority-modal/validate-care-line-priority-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMaskModule } from 'ngx-mask';
import { EmailConfirmationEndMedicalCareModalComponent } from './components/email-confirmation-end-medical-care-modal/email-confirmation-end-medical-care-modal.component';
import { LeaveRoomConfirmModalComponent } from './components/twilio-video/pages/participant/leave-room-confirm-modal/leave-room-confirm-modal.component';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { CalendarModule, DateAdapter, MOMENT } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import moment from 'moment';
import { CalendarScheduleComponent } from './components/calendar-schedule/calendar-schedule.component';
import { GenericErrorModalComponent } from './components/generic-error-modal/generic-error-modal.component';
import localept from '@angular/common/locales/pt';
import { SecundaryHeaderV2Component } from './components/secundary-header-v2/secundary-header-v2.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ActivePrincipleTagComponent } from './components/active-principle-tag/active-principle-tag.component';
import { RemoveActivePrincipleModalComponent } from './components/active-principle-tag/remove-active-principle-modal/remove-active-principle-modal.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { KpiStatusComponent } from './components/kpi-status/kpi-status.component';
import { ValidateSuspicionModalComponent } from './components/validate-suspicion-modal/validate-suspicion-modal.component';
import { CompletePatientJourneyModalComponent } from './components/patient-list/complete-patient-journey-modal/complete-patient-journey-modal.component';
import { FavoriteNameModalComponent } from './components/favorite-name-modal/favorite-name-modal.component';
import { BleScanModalComponent } from './components/ble-scan-modal/ble-scan-modal.component';
import { GenericDisplayTagComponent } from './components/generic-display-tag/generic-display-tag.component';
import { TasksQueuesModalComponent } from './components/tasks-queues-modal/tasks-queues-modal.component';
import { BleErrorModalComponent } from './components/ble-error-modal/ble-error-modal.component';
import { StorageMigrationModalComponent } from './components/storage-migration-modal/storage-migration-modal.component';
import { FormscrollDirective } from './directives/formscroll.directive';
import { ChangePinConfirmModalComponent } from './components/change-pin-confirm-modal/change-pin-confirm-modal.component';
import { CompanionPostPresenceInfoComponent } from './components/patient-list-reprint-report-modal/companion-post-presence-info/companion-post-presence-info.component';
import { GetPhoneModalComponent } from './components/get-phone-modal/get-phone-modal.component';
import { SignatureManagerComponent } from './components/menu/signature-manager/signature-manager.component';
import { SafeIDAuthenticatorComponent } from './components/menu/signature-manager/safeID-authenticator/safeID-authenticator.component';
import { SignatureCredentialsModalComponent } from './components/signature-credentials-modal/signature-credentials-modal.component';
import { SecurityAlertModalComponent } from './components/security-alert-modal/security-alert-modal.component';
import { ReportViewSelectModalComponent } from './components/report-view-select-modal/report-view-select-modal.component';
import { TissGuideManagementFloatingWindowComponent } from './components/tiss-guides/tiss-guide-management-floating-window/tiss-guide-management-floating-window.component';
import { TissGuideManagementModalComponent } from './components/tiss-guides/tiss-guide-management-modal/tiss-guide-management-modal.component';
import { TissGuideSpSadtModalComponent } from './components/tiss-guides/tiss-guide-sp-sadt-modal/tiss-guide-sp-sadt-modal.component';
import { TissGuideFeesModalComponent } from './components/tiss-guides/tiss-guide-fees-modal/tiss-guide-fees-modal.component';
import { FeesProcedureModalComponent } from './components/tiss-guides/tiss-guide-fees-modal/fees-procedure-modal/fees-procedure-modal.component';
import { FeesProcedureFormComponent } from './components/tiss-guides/tiss-guide-fees-modal/fees-procedure-form/fees-procedure-form.component';
import { TissGuideAdmissionModalComponent } from './components/tiss-guides/tiss-guide-admission-modal/tiss-guide-admission-modal.component';
import { AdmissionProcedureComponent } from './components/tiss-guides/tiss-guide-admission-modal/admission-procedure/admission-procedure.component';
import { TissGuideAppointmentComponent } from './components/tiss-guides/tiss-guide-appointment/tiss-guide-appointment.component';
import { TissGuideManagementComponent } from './components/tiss-guides/tiss-guide-management/tiss-guide-management.component';
import { SpSadtExecutedProcedureTableComponent } from './components/tiss-guides/tiss-guide-sp-sadt-modal/sp-sadt-executed-procedure-table/sp-sadt-executed-procedure-table.component';
import { SpSadtExecutedProcedureFormModalComponent } from './components/tiss-guides/tiss-guide-sp-sadt-modal/sp-sadt-executed-procedure-form-modal/sp-sadt-executed-procedure-form-modal.component';
import { SpSadtRequestedProcedureFormComponent } from './components/tiss-guides/tiss-guide-sp-sadt-modal/sp-sadt-requested-procedure-form/sp-sadt-requested-procedure-form.component';
import { TissGuideNotSavedConfirmModalComponent } from './components/tiss-guides/tiss-guide-not-saved-confirm-modal/tiss-guide-not-saved-confirm-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { UppercaseFormDirective } from './directives/uppercase-form.directive';
import { PatientDetailsModalComponent } from './components/patient-details-modal/patient-details-modal.component';
import { OccupiedRemoveModalComponent } from './components/occupied-remove-modal/occupied-remove-modal.component';
import { AttendanceOccupiedRemoveModalComponent } from './components/attendance-occupied-remove-modal/attendance-occupied-remove-modal.component';
import { MetaDataComponent } from './components/meta-data/meta-data.component';
import { AgeMaskDirective } from './directives/agr-mask.directive';
import { HoldModalComponent } from './components/hold-modal/hold-modal.component';
import { PatientListLiberateAttendanceModalComponent } from './components/patient-list-liberate-attendance-modal/patient-list-liberate-attendance-modal.component';
import { PatientListCallConfirmModalComponent } from './components/patient-list-call-confirm-modal/patient-list-call-confirm-modal.component';
import { PatientListAlertCallConfirmModalComponent } from './components/patient-list-alert-call-confirm-modal/patient-list-alert-call-confirm-modal.component';
import { PatientListAttendanceConfirmModalComponent } from './components/patient-list-attendance-confirm-modal/patient-list-attendance-confirm-modal.component';
import { PatientListLiberateCallConfirmModalComponent } from './components/patient-list-liberate-call-confirm-modal/patient-list-liberate-call-confirm-modal.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { VidaaSAuthenticatorComponent } from './components/menu/signature-manager/vidaas-authenticator/vidaas-authenticator.component';
import { TableComponent } from './components/table/table.component';
import { SaveFavoritePrescriptionModalComponent } from './components/favorite-prescription-modal/favorite-prescription-modal.component';
import { SelectFavoriteNonStandardMedicineModalComponent } from './components/select-favorite-non-standard-medicine-modal/select-favorite-non-standard-medicine-modal.component';
import { SelectFavoriteExamPrescriptionModalComponent } from './components/select-favorite-exam-prescription-modal/select-favorite-exam-prescription-modal.component';
import { InvalidPrescriptionModalComponent } from './components/invalid-prescription-modal/invalid-prescription-modal.component';
import { SelectFavoriteMedicineModalComponent } from './components/select-favorite-medicine-modal/select-favorite-medicine-modal.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ProtocolV2Component } from './components/protocol-v2/protocol-v2.component';
import { WarnOfflineModalComponent } from './components/protocol-v2/warn-offline-modal/warn-offline-modal.component';
import { HealthGuidelineTriageModalComponent } from './components/protocol-v2/health-guideline-triage-modal/health-guideline-triage-modal.component';
import { GreaterGravityModalComponent } from './components/protocol-v2/greater-gravity-modal/greater-gravity-modal.component';
import { AlertGreaterGravityModalComponent } from './components/protocol-v2/alert-greater-gravity-modal/alert-greater-gravity-modal.component';
import { DiscriminatorComponent } from './components/protocol-v2/discriminator/discriminator.component';
import { TemperatureModalComponent } from './components/protocol-v2/discriminator/temperature-modal/temperature-modal.component';
import { SepsisModalComponent } from './components/protocol-v2/discriminator/sepsis-modal/sepsis-modal.component';
import { SubDiscriminatorComponent } from './components/protocol-v2/discriminator/sepsis-modal/sub-discriminator/sub-discriminator.component';
import { SepsisCriancaModalComponent } from './components/protocol-v2/discriminator/sepsis-crianca-modal/sepsis-crianca-modal.component';
import { SaturationModalComponent } from './components/protocol-v2/discriminator/saturation-modal/saturation-modal.component';
import { RespiratoryFrequencyModalComponent } from './components/protocol-v2/discriminator/respiratory-frequency-modal/respiratory-frequency-modal.component';
import { PressureModalComponent } from './components/protocol-v2/discriminator/pressure-modal/pressure-modal.component';
import { PainModalComponent } from './components/protocol-v2/discriminator/pain-modal/pain-modal.component';
import { NumericModalComponent } from './components/protocol-v2/discriminator/numeric-modal/numeric-modal.component';
import { HeartRateModalComponent } from './components/protocol-v2/discriminator/heart-rate-modal/heart-rate-modal.component';
import { PreviousStateModalComponent } from './components/protocol-v2/discriminator/heart-rate-modal/previous-state-modal/previous-state-modal.component';
import { GlucoseModalComponent } from './components/protocol-v2/discriminator/glucose-modal/glucose-modal.component';
import { GlasgowModalComponent } from './components/protocol-v2/discriminator/glasgow-modal/glasgow-modal.component';
import { CapillaryPerfusionModalComponent } from './components/protocol-v2/discriminator/capillary-perfusion-modal/capillary-perfusion-modal.component';
import { CapillaryGlucoseModalComponent } from './components/protocol-v2/discriminator/capillary-glucose-modal/capillary-glucose-modal.component';
import { AcidosisModalComponent } from './components/protocol-v2/discriminator/capillary-glucose-modal/acidosis-modal/acidosis-modal.component';
import { ActiveMedicationStockComponent } from './components/active-medication-stock/active-medication-stock.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PatientLinkBedModalComponent } from './components/patient-link-bed-modal/patient-link-bed-modal.component';

registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [
    UserInfosComponent,
    ModuleInfosComponent,
    ModuleMenuComponent,
    RankInfosComponent,
    MenuComponent,
    FilterSelectComponent,
    FilterSelectModalComponent,
    EmptyListComponent,
    LoadingListComponent,
    SecundaryHeaderComponent,
    ChangePinConfirmModalComponent,
    LoadingProgressBarComponent,
    SelectComponent,
    Select2Component,
    TriageResumeComponent,
    AttendanceStatusComponent,
    GenericIndicatorDisplayComponent,
    SelectRoomModalComponent,
    StatusBulletComponent,
    SubMenuComponent,
    EvadePatientModalComponent,
    NotSeletedRoomComponent,
    VitalHistoryModalComponent,
    TagComponent,
    NetworkTestModalComponent,
    PatientStatusTagComponent,
    ObservationForwardModalComponent,
    ObservationForwardConfirmModalComponent,
    ObservationForwardBedConfirmModalComponent,
    PrescriptionListComponent,
    MedicalPrescriptionModalComponent,
    GravityTagComponent,
    UpgradeModalComponent,
    ExternalPrescriptionModalComponent,
    SadtModalComponent,
    ReportSelectModalComponent,
    TwilioVideoComponent,
    CameraComponent,
    ParticipantComponent,
    SettingsComponent,
    DeviceSelectComponent,
    FlowSequenceModalComponent,
    NotificationComponent,
    NotificationDisplayModalComponent,
    RankInfosComponent,
    withoutPermissionEvadeModal,
    RoomOccupiedConfirmModalComponent,
    RoomOccupiedModalComponent,
    RoomOccupiedRemoveModalComponent,
    CallPatientModalComponent,
    PatientListReprintReportModalComponent,
    AllergyTagComponent,
    RemoveAllergyModalComponent,
    SelfTriageResumeModalComponent,
    PinCertificateModalComponent,
    IframePreviewModalComponent,
    CareLineModalComponent,
    ModuleFunctionalityComponent,
    LeaveRoomConfirmModalComponent,
    FloatingButtonComponent,
    ValidateCareLinePriorityModalComponent,
    EmailConfirmationEndMedicalCareModalComponent,
    CalendarScheduleComponent,
    SecundaryHeaderV2Component,
    GenericErrorModalComponent,
    AlertModalComponent,
    ActivePrincipleTagComponent,
    RemoveActivePrincipleModalComponent,
    PatientListComponent,
    KpiStatusComponent,
    ValidateSuspicionModalComponent,
    CompletePatientJourneyModalComponent,
    FavoriteNameModalComponent,
    BleScanModalComponent,
    GenericDisplayTagComponent,
    TasksQueuesModalComponent,
    BleErrorModalComponent,
    StorageMigrationModalComponent,
    FormscrollDirective,
    UppercaseFormDirective,
    AgeMaskDirective,
    CompanionPostPresenceInfoComponent,
    GetPhoneModalComponent,
    SignatureManagerComponent,
    SafeIDAuthenticatorComponent,
    SignatureCredentialsModalComponent,
    SecurityAlertModalComponent,
    ReportViewSelectModalComponent,
    TissGuideAppointmentComponent,
    TissGuideManagementFloatingWindowComponent,
    TissGuideManagementModalComponent,
    TissGuideSpSadtModalComponent,
    SpSadtExecutedProcedureTableComponent,
    SpSadtExecutedProcedureFormModalComponent,
    TissGuideFeesModalComponent,
    FeesProcedureModalComponent,
    SpSadtRequestedProcedureFormComponent,
    FeesProcedureFormComponent,
    TissGuideAdmissionModalComponent,
    AdmissionProcedureComponent,
    FeesProcedureFormComponent,
    TissGuideManagementComponent,
    TissGuideNotSavedConfirmModalComponent,
    ConfirmModalComponent,
    PatientDetailsModalComponent,
    OccupiedRemoveModalComponent,
    MetaDataComponent,
    AttendanceOccupiedRemoveModalComponent,
    HoldModalComponent,
    PatientListLiberateAttendanceModalComponent,
    PatientListAttendanceConfirmModalComponent,
    PatientListCallConfirmModalComponent,
    PatientListAlertCallConfirmModalComponent,
    PatientListLiberateCallConfirmModalComponent,
    AutocompleteComponent,
    VidaaSAuthenticatorComponent,
    TableComponent,
    SaveFavoritePrescriptionModalComponent,
    SelectFavoriteNonStandardMedicineModalComponent,
    SelectFavoriteExamPrescriptionModalComponent,
    InvalidPrescriptionModalComponent,
    SelectFavoriteMedicineModalComponent,
    ProtocolV2Component,
    WarnOfflineModalComponent,
    HealthGuidelineTriageModalComponent,
    GreaterGravityModalComponent,
    AlertGreaterGravityModalComponent,
    DiscriminatorComponent,
    TemperatureModalComponent,
    SepsisModalComponent,
    SubDiscriminatorComponent,
    SepsisCriancaModalComponent,
    SaturationModalComponent,
    RespiratoryFrequencyModalComponent,
    PressureModalComponent,
    PainModalComponent,
    NumericModalComponent,
    HeartRateModalComponent,
    PreviousStateModalComponent,
    GlucoseModalComponent,
    GlasgowModalComponent,
    CapillaryPerfusionModalComponent,
    CapillaryGlucoseModalComponent,
    AcidosisModalComponent,
    SelectFavoriteMedicineModalComponent,
    ActiveMedicationStockComponent,
    PatientLinkBedModalComponent,
    ProtocolV2Component,
    WarnOfflineModalComponent,
    HealthGuidelineTriageModalComponent,
    GreaterGravityModalComponent,
    AlertGreaterGravityModalComponent,
    DiscriminatorComponent,
    TemperatureModalComponent,
    SepsisModalComponent,
    SubDiscriminatorComponent,
    SepsisCriancaModalComponent,
    SaturationModalComponent,
    RespiratoryFrequencyModalComponent,
    PressureModalComponent,
    PainModalComponent,
    NumericModalComponent,
    HeartRateModalComponent,
    PreviousStateModalComponent,
    GlucoseModalComponent,
    GlasgowModalComponent,
    CapillaryPerfusionModalComponent,
    CapillaryGlucoseModalComponent,
    AcidosisModalComponent,
    SelectFavoriteMedicineModalComponent,
    ActiveMedicationStockComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    CdkAccordionModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    MatProgressBarModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatDialogModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatRadioModule,
    MatPaginatorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SchedulerModule.forRoot({ locale: 'br', headerDateFormat: 'daysRange' }),
  ],
  exports: [
    MenuComponent,
    FilterSelectComponent,
    EmptyListComponent,
    LoadingListComponent,
    SecundaryHeaderComponent,
    LoadingProgressBarComponent,
    ChangePinConfirmModalComponent,
    SelectComponent,
    Select2Component,
    TriageResumeComponent,
    AttendanceStatusComponent,
    GenericIndicatorDisplayComponent,
    StatusBulletComponent,
    NotSeletedRoomComponent,
    TagComponent,
    GravityTagComponent,
    PatientStatusTagComponent,
    PrescriptionListComponent,
    MedicalPrescriptionModalComponent,
    UpgradeModalComponent,
    ExternalPrescriptionModalComponent,
    SadtModalComponent,
    ReportSelectModalComponent,
    TwilioVideoComponent,
    FlowSequenceModalComponent,
    AllergyTagComponent,
    ActivePrincipleTagComponent,
    SelfTriageResumeModalComponent,
    PinCertificateModalComponent,
    ModuleFunctionalityComponent,
    FloatingButtonComponent,
    ValidateCareLinePriorityModalComponent,
    CalendarScheduleComponent,
    AlertModalComponent,
    SecundaryHeaderV2Component,
    PatientListComponent,
    KpiStatusComponent,
    FavoriteNameModalComponent,
    GenericDisplayTagComponent,
    StorageMigrationModalComponent,
    FormscrollDirective,
    UppercaseFormDirective,
    AgeMaskDirective,
    SignatureCredentialsModalComponent,
    TissGuideManagementFloatingWindowComponent,
    TissGuideManagementModalComponent,
    TissGuideSpSadtModalComponent,
    SpSadtRequestedProcedureFormComponent,
    TissGuideManagementComponent,
    TissGuideNotSavedConfirmModalComponent,
    ConfirmModalComponent,
    MetaDataComponent,
    AttendanceOccupiedRemoveModalComponent,
    HoldModalComponent,
    PatientListLiberateAttendanceModalComponent,
    PatientListAttendanceConfirmModalComponent,
    PatientListCallConfirmModalComponent,
    PatientListAlertCallConfirmModalComponent,
    PatientListLiberateCallConfirmModalComponent,
    AutocompleteComponent,
    TableComponent,
    ProtocolV2Component,
    WarnOfflineModalComponent,
    HealthGuidelineTriageModalComponent,
    GreaterGravityModalComponent,
    AlertGreaterGravityModalComponent,
    DiscriminatorComponent,
    TemperatureModalComponent,
    SepsisModalComponent,
    SubDiscriminatorComponent,
    SepsisCriancaModalComponent,
    SaturationModalComponent,
    RespiratoryFrequencyModalComponent,
    PressureModalComponent,
    PainModalComponent,
    NumericModalComponent,
    HeartRateModalComponent,
    PreviousStateModalComponent,
    GlucoseModalComponent,
    GlasgowModalComponent,
    CapillaryPerfusionModalComponent,
    CapillaryGlucoseModalComponent,
    AcidosisModalComponent,
    TableComponent,
    ActiveMedicationStockComponent,
    PatientLinkBedModalComponent,
    ProtocolV2Component,
    WarnOfflineModalComponent,
    HealthGuidelineTriageModalComponent,
    GreaterGravityModalComponent,
    AlertGreaterGravityModalComponent,
    DiscriminatorComponent,
    TemperatureModalComponent,
    SepsisModalComponent,
    SubDiscriminatorComponent,
    SepsisCriancaModalComponent,
    SaturationModalComponent,
    RespiratoryFrequencyModalComponent,
    PressureModalComponent,
    PainModalComponent,
    NumericModalComponent,
    HeartRateModalComponent,
    PreviousStateModalComponent,
    GlucoseModalComponent,
    GlasgowModalComponent,
    CapillaryPerfusionModalComponent,
    CapillaryGlucoseModalComponent,
    AcidosisModalComponent,
    TableComponent,
    ActiveMedicationStockComponent
  ],
  providers: [
    { provide: MOMENT, useValue: moment },
    { provide: LOCALE_ID, useValue: 'pt' },
  ]
})
export class SharedModule { }
