import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { AlertService, AlertType } from '../../services/alert.service';
import { DownloadReportService } from '../../services/API/medical-record/download-report.service';
import { FlowchartService } from '../../services/API/risk-classification/flowchart.service';
import { ReportModalComponent } from '../report-modal/report-modal.component';
import { FormGroup } from '@angular/forms';
import { MaskService, Masks } from '../../services/mask.service';

@Component({
  selector: 'app-triage-resume',
  templateUrl: './triage-resume.component.html',
  styleUrls: ['./triage-resume.component.css']
})
export class TriageResumeComponent implements OnInit {

  constructor(private flowchartService: FlowchartService,
    private alertService: AlertService,
    private downloadReportService: DownloadReportService,
    public maskService: MaskService,
    public dialog: MatDialog) { }


  @Input() triage: TriageStruct = new TriageStruct();
  @Input() isHistorical: boolean = false;
  @Input() isTeletriage: boolean = false;
  @Input() patientForm: FormGroup = null;
  @Input() allergy: string = "";
  @Input() protocolName: string = "";
  @Input() pain: number = null;
  @Input() painType: number = null;
  @Input() specialNecessityName: string = "";
  @Input() isTelephonceClassification: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() hasEndDateTime: boolean = true;
  @Output() next = new EventEmitter<any>();

  public serviceTime: string;
  public min_less_seven: number = 2;
  public max_less_seven: number = 5;
  public min_over_seven: number = 6;
  public max_over_seven: number = 9;

  public masks: Masks;

  ngOnInit(): void {
    this.getFlowchartData();
    this.masks = this.maskService.getMasks();
  }

  submit() {
    this.next.emit();
  }

  getFlowchartData() {
    if (!this.protocolName && this.triage.idFlowchart) {
      this.flowchartService.getTheFlowchart(this.triage.idFlowchart).subscribe({
        next: (response) => {
          if (response.isError) {
            this.alertService.show('Erro', response.errorDescription, AlertType.error);
            return;
          }
          this.protocolName = response.flowchart.protocolName;
        }
      });
    }
  }

  downloadTriageReport() {
    this.downloadReportService.downloadReport(this.triage.idEpisode, this.triage.triageReportName).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        let reportDialog = this.dialog.open(ReportModalComponent, {
          data: {
            reportName: response.reportName,
            reportPdf64: response.reportPdf64,
          },
        });
        reportDialog.afterClosed().subscribe(result => {
        });
      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }
}
