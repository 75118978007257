<div mat-dialog-content>
    <div class="mat-dialog-content">
        <app-loading-list *ngIf="isLoading"></app-loading-list>
        <ng-container *ngIf="!isLoading">
            <div class="title-header">
                <h1><b>Itens do kit</b></h1>
            </div>
            <h3>{{this.kit.kit.kitName}}</h3>
            <h5>Manipulação</h5>
    
            <div class="row">
                <div class="col-12" *ngFor="let item of this.kit.items; let index = index;">
                    <div class="col-7">
                        <mat-form-field appearance="outline">
                            <mat-label>Item</mat-label>
                            <input matInput type="text" readonly [value]="item.codeWithName">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input matInput type="number" readonly [value]="item.amount">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-12" *ngFor="let item of this.kit.medicines; let index = index;">
                    <div class="col-7">
                        <mat-form-field appearance="outline">
                            <mat-label>Medicamento</mat-label>
                            <input matInput type="text" readonly [value]="item.medicineDescription">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade</mat-label>
                            <input matInput type="number" readonly [value]="item.amount">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <app-empty-list *ngIf="this.kit.items != undefined && this.kit.items.length == 0"></app-empty-list>    
        </ng-container>
        
    </div>
</div>
<div mat-dialog-footer class="footer-modal">
    <div class="footer-audit-modal-warning col-6">
        <button mat-flat-button color="accent" class="btn-block" (click)="close()">
            <span>Voltar</span>
        </button>
    </div>
</div>