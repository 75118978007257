import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BleErrorModalComponent } from '../components/ble-error-modal/ble-error-modal.component';
declare var iziToast:any;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private dialog: MatDialog,) { }

  public show(title:string, menssage:string, type:AlertType){
    if(type == AlertType.success){
      iziToast.success({
        title: title,
        message: menssage,
      });
    }else if(type == AlertType.warning){
      iziToast.warning({
        title: title,
        message: menssage,
      });
    } else if(type == AlertType.error){
      iziToast.error({
        title: title,
        message: menssage,
      });
    } else if(type == AlertType.info){
      iziToast.info({
        title: title,
        message: menssage,
      });
    } 
    else{
      iziToast.show({
        title: title,
        message: menssage,
        theme:'dark'
      });
    }

    
  }


  public showBluetoothAlert(title:string, menssage:string, type:AlertType){
    let $this = this;
    if(type == AlertType.success){
      iziToast.success({
        title: title,
        message: menssage,
      });
    }else if(type == AlertType.warning){
      iziToast.warning({
        title: title,
        message: menssage,
      });
    } else if(type == AlertType.error){
      var toast = document.querySelector('.iziToast'); // Selector of your toast
      if(!toast){
        iziToast.info({
          title: title,
          message: menssage,
          close: true,
          timeout: 10000,
          position:'bottomCenter',
          buttons: [
            ['<button>Instalar</button>', function () {
              $this.openBluetoothErrorModal();
            }, true], // true to focus  
          ],
        });
      }
      else{
        iziToast.progress({}, toast).reset();
      }
    } else if(type == AlertType.info){
      iziToast.info({
        title: title,
        message: menssage,
      });
    } 
    else{
      iziToast.show({
        title: title,
        message: menssage,
        theme:'dark'
      });
    }

    
  }

  openBluetoothErrorModal() {

    this.dialog.open(BleErrorModalComponent).afterClosed().subscribe();
      
  }
}

export enum AlertType{
  warning,
  error,
  success,
  info
}