import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetaDataTypeEnum } from 'src/app/shared/enum/meta-data/meta-data-type.enum';
import { PrioritiesStatusEnum } from 'src/app/shared/enum/priorities-status.enum';
import { CalcAgeService } from 'src/app/shared/services/API/medical-record/calc-age.service';
import { EpisodeService } from 'src/app/shared/services/API/medical-record/episode.service';
import { MetaDataService } from 'src/app/shared/services/API/meta-data/meta-data.service';
import { PriorityService } from 'src/app/shared/services/API/totem/priority.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { PriorityModel } from 'src/app/shared/services/models/totem/priority.model';
import { CalcAgeResponse } from 'src/app/shared/services/responses/medical-record/calc-age.response';
import { EpisodeStruct } from 'src/app/shared/services/structs/medical-record/episode.struct';
import { PatientStruct } from 'src/app/shared/services/structs/medical-record/patient.struct';
import { MetaDataStruct } from 'src/app/shared/services/structs/meta-data/meta-data.struct';
import { TriageStruct } from 'src/app/shared/services/structs/orchestrator-patient/triage.struct';
import { NameValueGenericStruct } from 'src/app/shared/structs/name-value-generic.struct';

@Component({
  selector: 'app-patient-details-modal',
  templateUrl: './patient-details-modal.component.html',
  styleUrls: ['./patient-details-modal.component.css']
})
export class PatientDetailsModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<PatientDetailsModalComponent>,
    private alertService: AlertService,
    private calcAgeService: CalcAgeService,
    private maskService: MaskService,
    private episodeService: EpisodeService,
    private priorityService: PriorityService,
    private metaDataService: MetaDataService,
  ) { }
  
  public metaDataTypeEnum: typeof MetaDataTypeEnum = MetaDataTypeEnum;
  public autismEnum: number = PrioritiesStatusEnum.AutistaTEA;
  public overSixtyYearsEnum: number = PrioritiesStatusEnum.MaiorDe60Anos;
  public overEightyYearsEnum: number = PrioritiesStatusEnum.MaiorDe80Anos;
  
  public isSearchEpisode: boolean = true;
  public lastTriage: TriageStruct;
  public patient: PatientStruct = null;
  public episodeStruct: EpisodeStruct;
  public listPriority: PriorityModel[] = [];
  public listPriorityEpisode: PriorityModel[] = [];
  public listValuePatientMetaData: NameValueGenericStruct[] = [];
  public listMetaDataStruct: MetaDataStruct[] = [];

  public listIdPatientPriorities: number[] = [];
  public idEpisode: number;

  public patientAge: string;
  public patientNecessities: string = '';

  public isLoading: boolean = false;
  public isFirstLoading: boolean = true;
  public isUnidentifiedPatient: boolean = false;
  public showSectionAddress: boolean = false;
  public showSectionOrigin: boolean = false;
  public showSectionCompanion: boolean = false;

  ngOnInit(): void {
    this.idEpisode = this.data.idEpisode;
    this.lastTriage = this.data.lastTriage;
    this.isSearchEpisode = this.data.isSearchEpisode;
    this.patient = this.data.patient;
    
    if (this.isSearchEpisode === false && this.patient){
      this.isFirstLoading = false;
      this.configureBooleanValuesAndMetaData();
    }
    else{
      this.populatePatientData();
    }
      
  }

  closeModal() {
    this.matDialogRef.close();
  }

  populatePatientData() {
    this.isLoading = true;
    this.episodeService.getPatientByEpisode(this.idEpisode).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.episodeStruct = response.episodeStruct;
        this.isUnidentifiedPatient = this.episodeStruct.isUnidentifiedPatient;

        if (response.priorities && response.priorities.length > 0) {
          this.listIdPatientPriorities = response.priorities;
          this.populatePriorityList();
        }

        if (response.hasPatient) {
          this.patient = response.patient;
          this.configureBooleanValuesAndMetaData();
        }
        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      }
    });
  }

  populatePriorityList() {
    this.priorityService.listPriority().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);

          return;
        }
        this.listPriority = response.listPriority;

        this.listPriorityEpisode = this.listIdPatientPriorities.map(numberPriority => {
          let priority = this.listPriority.find(x => x.idPriority == numberPriority)
          if (priority) return priority;
        });

      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getListMetaData() {
    this.metaDataService.getAllMetaData().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
        }

        this.listMetaDataStruct = response.listMetaDataStruct;

        if(this.listMetaDataStruct.length > 0) {
          for (let item of this.listMetaDataStruct) {
            let nameValueGeneric = new NameValueGenericStruct();
            let valuePatient = this.patient.metaData.find(x => x.key == item.metaData.idMetaData);
            if (valuePatient) {
              nameValueGeneric.id = item.metaData.idMetaDataType;
              nameValueGeneric.name = item.metaData.metaDataName;
              nameValueGeneric.value = valuePatient.value;
              this.listValuePatientMetaData.push(nameValueGeneric);
            }
          }
        }

      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  configureBooleanValuesAndMetaData(){
    if(this.patient.zipCode || this.patient.street || this.patient.houseNumber 
      || this.patient.apartmentNumber || this.patient.neighborhood || this.patient.city 
      || this.patient.state || this.patient.country)
      this.showSectionAddress = true;
    if(this.patient.originName|| this.patient.arrivalTypeName || (this.patient.listPatientNecessities && this.patient.listPatientNecessities.length > 0) 
      || this.patient.observation){
      this.showSectionOrigin = true;
      if(this.patient.listPatientNecessities && this.patient.listPatientNecessities.length > 0)
        this.patient.listPatientNecessities.forEach((item, index) => {
          this.patientNecessities += ` ${item.necessityName}` + ((index + 1 == this.patient.listPatientNecessities.length) ? `.`: `, `);
        })
    }
    if(this.patient.companionName || this.patient.companionPhone || this.patient.companionCpf || this.patient.companionParentage)
      this.showSectionCompanion = true;
      
    if (this.patient.birthDate) {
      this.calcAgeService.getAge(this.maskService.formatDateToString(this.patient.birthDate, false)).subscribe((calcAgeResponse: CalcAgeResponse) => {
        this.patientAge = `${calcAgeResponse.year}a ${calcAgeResponse.months}m ${calcAgeResponse.days}d`;
      });
    }

    if (this.patient.metaData && this.patient.metaData.length > 0 && this.idEpisode) {
      this.getListMetaData();
    }
  }
}
