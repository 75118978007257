<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="head-confirm-modal-list">
                <h2>Categorizações embutidas no protocolo {{this.healthGuideline.healthGuidelineName}}</h2>
            </div>

            <div class="body-confirm-modal-list">
                <div *ngFor="let item of listCategorization" class="row">
                    <div class="categorization-description col-10">
                        <h3 class="categorization-title">{{item.categorizationName}}</h3>
                        <span>{{item.description}}</span>
                    </div>
                    <div class="col-2 radio-align">
                        <input [value]="item" class="radio-sim" type="radio"
                        id="radio-{{item.idHealthGuidelineCategorization}}-sim"
                        name="radio-sim">
                        <label (click)="selectCategorization(item)" class="label-radio-sim"
                            for="radio-{{item.idHealthGuidelineCategorization}}-sim">
                            <img src="assets/images/icone-radio-sim.svg" />
                        </label>
                    </div>
                </div>

                <div *ngIf="this.selectedCategoriation">Data e hora de início do sintoma:</div>
                
            </div>
            <div *ngIf="this.selectedCategoriation" class="footer-confirm-modal-list">
                    <div class="row">
                        <div class="col-12 col-sm-8">
                            <mat-form-field appearance="outline">
                                <mat-label>Data</mat-label>
                                <input matInput type="text" formControlName="symptomStartDate" [mask]="masks.date">
                                <mat-error *ngIf="model.get('symptomStartDate')?.errors?.invalidDate">{{model.get('symptomStartDate')?.errors?.invalidDate}}</mat-error>
                                <mat-error *ngIf="model.get('symptomStartDate').invalid && !model.get('symptomStartDate')?.errors?.invalidDate">Informe a data de início do sintoma</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-6 col-sm-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Hora</mat-label>
                                <input matInput type="text" formControlName="symptomStartTime" [mask]="masks.timeUnit.mask"  [pattern]="masks.timeUnit.pattern">
                                <mat-error *ngIf="model.get('symptomStartTime').invalid">Informe a hora de início do sintoma</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
            </div>
            <div class="col-12 button-container">
                <div class="col-5">
                    <button mat-flat-button type="submit" color="primary" class="btn-block" [disabled]="!this.selectedCategoriation">
                        <span>Continuar</span>
                    </button>
                </div>
                <div class="col-5">
                    <button mat-flat-button type="button" color="accent" class="btn-block" (click)="back()">
                        <span>Voltar</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>


