import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { BloodPressureStruct } from "../../structs/orchestrator-patient/blood-pressure.struct";
import { GlasgowStruct } from "../../structs/orchestrator-patient/glasgow.struct";
import { GlucoseStruct } from "../../structs/orchestrator-patient/glucose.struct";
import { HeartRateStruct } from "../../structs/orchestrator-patient/heart-rate.struct";
import { PainStruct } from "../../structs/orchestrator-patient/pain.struct";
import { RespiratoryFrequencyStruct } from "../../structs/orchestrator-patient/respiratory-frequency.struct";
import { SaturationStruct } from "../../structs/orchestrator-patient/saturation.struct";
import { TemperatureStruct } from "../../structs/orchestrator-patient/temperature.struct";
import { PatientCareLinePriorityStruct } from "../../structs/care-line-priority/patient-care-line-priority-struct";
import { AllergyStruct } from "../../structs/medical-record/allergy.struct";
import { MonitoringDataToDeteriorationProtocolScoreRangeCalcStruct } from "../../structs/risk-classification/monitoring-data-to-deterioration-protocol-score-range-calc.struct";

export class HumanizedHeaderResponse extends ReturnStruct {
    public age: string;
    public birthDate: string;
    public gravity: string;
    public gravityColorCode: string;
    public heartRate: number;
    public idEpisode: number;
    public idPatient: number;
    public name: string;
    public idCareLinePriority: number;
    public idCareLinePriorityStatus: number;
    public pain: string;
    public painColorCode: string[];
    public priority: Array<number>;
    public regress: string;
    public token: string;
    public waitingTime: string;
    public socialName: string;
    public respiratoryFrequency: number;
    public saturation: number;
    public temperature: number;
    public bloodPressure: number;
    public glucose: number;
    public listBloodPressure: Array<BloodPressureStruct>;
    public listGlucose: Array<GlucoseStruct>;
    public listHeartRate: Array<HeartRateStruct>;
    public listRespiratoryFrequency: Array<RespiratoryFrequencyStruct>;
    public listSaturation: Array<SaturationStruct>;
    public listTemperature: Array<TemperatureStruct>;
    public listGlasgow: Array<GlasgowStruct>;
    public listPain: Array<PainStruct>;
    public listAllergyStruct: AllergyStruct[] = [];
    public glasgow: number;
    public patientCareLinePriorityStruct: PatientCareLinePriorityStruct;
    public isSchedule: boolean;
    public dateStartSchedule: Date;
    public medicalSpecialityName: string;
    public suspicionEvaluationMotive: string;
    public idUserSuspicionEvaluation: number;
    public isSuspicionEvaluated: boolean;
    public suspicionUserName: string;
    public suspicionStatusName: string;
    public suspicionName: string;
    public datetimeSuspicionEvaluation: Date;
    public isUnidentifiedPatient: boolean;
    public unidentifiedPatientDescription: string;
    public monitoringDataToDeteriorationProtocol: MonitoringDataToDeteriorationProtocolScoreRangeCalcStruct;
}