import { Pipe, PipeTransform } from '@angular/core';
import { StatusSchedulingEnum } from '../../enum/medical-record/status-scheduling.enum';
import { DateTimeStruct } from '../../services/structs/orchestrator-patient/date-time.struct';

@Pipe({
  name: 'filterStatusSadtScheduling'
})
export class FilterStatusSadtSchedulingPipe implements PipeTransform {

  transform(datetimeCheckEstimative: Date, idStatusScheduling: number, listDateTime: DateTimeStruct[], date: string, hour: number): string {
    if(!datetimeCheckEstimative
      && listDateTime && listDateTime[0].date == date
      && listDateTime[0].hour[0] == hour) {
      return '';
    } else if(!datetimeCheckEstimative) {
      return'hide';
    } else if(datetimeCheckEstimative) {
      let [day, month, year] = date.split('/').map(Number);
      let dateStatus = new Date(year, (month-1), day);
      datetimeCheckEstimative = new Date(datetimeCheckEstimative);
      if(datetimeCheckEstimative.getFullYear() == dateStatus.getFullYear()
          && datetimeCheckEstimative.getMonth() == dateStatus.getMonth()
          && datetimeCheckEstimative.getDate() == dateStatus.getDate()
          && datetimeCheckEstimative.getHours() == hour) {
        
        if(idStatusScheduling == StatusSchedulingEnum.Pendente)
          return 'pendente';
        else if(idStatusScheduling == StatusSchedulingEnum.AAprazar)
          return 'a-prazar';
        else if(idStatusScheduling == StatusSchedulingEnum.Aprazado)
          return 'aprazado';
        else if(idStatusScheduling == StatusSchedulingEnum.Concluido)
          return 'concluido';
        else if(idStatusScheduling == StatusSchedulingEnum.Atrasado)
          return 'atrasado';
        else if(idStatusScheduling == StatusSchedulingEnum.Suspenso)
          return 'suspenso';
        else if(idStatusScheduling == StatusSchedulingEnum.Agora)
          return 'agora';
        else return'hide';
      }
    }
    
    return'hide';
  }
}

