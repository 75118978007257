import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { PostPutKitRequest } from '../../requests/pharmacy/post-put-kit.request';
import { GetKitResponse } from '../../responses/pharmacy/get-kit.response';
import { ListKitResponse, ListKitSelectResponse } from '../../responses/pharmacy/list-kit.response';
import { KitMultiselectResponse } from '../../responses/pharmacy/kit-multiselect.response';
import { AdditionalElementsToKitSearchResponse } from '../../responses/pharmacy/additional-elements-to-kit-search.response';
import { AdditionalElementsToKitSearchRequest } from '../../requests/pharmacy/additional-elements-to-kit-search.request';

@Injectable({
  providedIn: 'root'
})
export class KitService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public listKit(searchText: string, idKitType: number): Observable<ListKitResponse> {
    let uri = `Kit?`;

    if (searchText)
      uri = uri + `searchText=${searchText}&`;
    if (idKitType)
      uri = uri + `idKitType=${idKitType}&`

    return this.httpClient.get<ListKitResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public listAllKit(): Observable<KitMultiselectResponse> {
    let uri = `Kit/getAll`;

    return this.httpClient.get<KitMultiselectResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getKit(idKit: number): Observable<GetKitResponse> {
    let uri = `Kit/idKit/${idKit}`;

    return this.httpClient.get<GetKitResponse>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public GetAdditionalElementsByIdKit(body: AdditionalElementsToKitSearchRequest): Observable<AdditionalElementsToKitSearchResponse> {

    let uri = `Kit/GetAdditionalElementsByKitsForPrescriptionReleaseForm`

    return this.httpClient.post<AdditionalElementsToKitSearchResponse>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public createKit(body: PostPutKitRequest): Observable<ReturnStruct> {

    let uri = `Kit`

    return this.httpClient.post<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public updateKit(idKit: number, body: PostPutKitRequest): Observable<ReturnStruct> {

    let uri = `Kit/idKit/${idKit}`

    return this.httpClient.put<ReturnStruct>(environment.urlApiPharmacy + uri, body, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public deleteKit(idKit: number): Observable<ReturnStruct> {

    let uri = `Kit/idKit/${idKit}`

    return this.httpClient.delete<ReturnStruct>(environment.urlApiPharmacy + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}