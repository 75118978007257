import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { MedicalPrescriptionCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-prescription-check.request';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { MedicalProcedureStruct } from 'src/app/shared/services/structs/medical-record/medical-procedure.struct';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { GetListMedicineStruct } from 'src/app/shared/services/structs/pharmacy/get-list-medicine.struct';
import { AlertModalComponent } from '../../alert-modal/alert-modal.component';
import { MedicalProcedureCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-procedure-check.request';

@Component({
  selector: 'app-late-pending-checks-modal',
  templateUrl: './late-pending-checks-modal.component.html',
  styleUrls: ['./late-pending-checks-modal.component.css']
})
export class LatePendingChecksModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LatePendingChecksModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public medicalPrescriptionStruct: MedicalPrescriptionStruct;
  public medicalProcedureStruct: MedicalProcedureStruct;
  public medicatedSolutionStruct: MedicatedSolutionStruct;

  public patientName: string;
  public birthDate: Date;
  public idEpisode: number;
  public allergiesToSave: AllergyModel[] = [];
  public listMedicine: GetListMedicineStruct;

  public listCheckedMedication: MedicalPrescriptionCheckRequest[] = [];
  public listCheckedProcedure: MedicalProcedureCheckRequest[] = [];

  public typeOfPendingCheck: string;

  ngOnInit(): void {
    this.setLocalVariablesFromData();
    this.setTypeOfPendingCheck();
  }

  setLocalVariablesFromData(): void {
    this.medicalPrescriptionStruct = this.data.medicalPrescriptionStruct;
    this.medicalProcedureStruct = this.data.medicalProcedureStruct;
    this.medicatedSolutionStruct = this.data.medicatedSolutionStruct;
    this.patientName = this.data.patientName;
    this.birthDate = this.data.birthDate;
    this.idEpisode = this.data.idEpisode;
    this.allergiesToSave = this.data.allergiesToSave;
    this.listMedicine = this.data.listMedicine;
  }

  setTypeOfPendingCheck(): void {
    if (this.medicalPrescriptionStruct) {
      this.typeOfPendingCheck = 'prescription';
    } else if (this.medicalProcedureStruct) {
      this.typeOfPendingCheck = 'procedure';
    } else if (this.medicatedSolutionStruct) {
      this.typeOfPendingCheck = 'solution';
    }
  }

  async confirmEdit(): Promise<void> {
    let isTwoButtonsModal = false;
    let title = 'Atenção!';
    let description = 'As alterações realizadas somente serão salvas após o salvamento da conduta.';
    let hasImage = true;
    await this.openAlertModal(isTwoButtonsModal, title, description, hasImage);

    let result = {};

    if (this.typeOfPendingCheck === 'prescription') {
      result = {
        medicalPrescriptionStruct: this.medicalPrescriptionStruct,
        listCheckedMedication: this.listCheckedMedication
      };
    }
    else if (this.typeOfPendingCheck === 'solution') {
      result = {
        medicatedSolutionStruct: this.medicatedSolutionStruct,
        listCheckedMedication: this.listCheckedMedication
      };
    }
    else if (this.typeOfPendingCheck === 'procedure') {
      result = {
        medicalProcedureStruct: this.medicalProcedureStruct,
        listCheckedProcedure: this.listCheckedProcedure
      };
    }

    this.dialogRef.close(result);
  }

  async openAlertModal(isTwoButtonsModal: boolean, title: string, description: string, hasImage: boolean): Promise<void> {
      return new Promise((resolve) => {
        const dialogRef = this.dialog.open(AlertModalComponent, {
          data: {
            isTwoButtonsModal: isTwoButtonsModal,
            title: title,
            description: description,
            hasImage: hasImage
          },
        });
        dialogRef.afterClosed().subscribe({
          next: result => {
            resolve();
            return;
          }
        });
      });
  }

  cancelEdit(): void {
    this.dialogRef.close();
  }
}
