import { ReturnStruct } from "../../../structs/return.struct";
import { AllergyModel } from "../../models/medical-record/allergy.model";

export class LookupResponse extends ReturnStruct {
    listGenders: Gender[];
    listRaces: Race[];
    listEducations: Education[];
    listMaritalStatuses: MaritalStatus[];
    listGravities: Gravity[];
    listPatientNecessities: PatientNecessity[];
    listOrigins: Origin[];
    listArrivalTypes: ArrivalType[];
    listSuspicion: Suspicion[];
    listStatus: EpisodeStatus[];
    listComorbidity: Comorbidity[];
    listAgeGroup: AgeGroup[];
    listPain: Pain[];
}

export class Race {
    public idRace: number;
    public raceName: string;
}

export class Gender {
    public idGender: number;
    public genderName: string;
}

export class Education {
    public idEducation: number;
    public educationName: string;
}

export class MaritalStatus {
    public idMaritalStatus: number;
    public maritalStatusName: string;
}

export class Gravity {
    public idGravity: number;
    public gravityName: string;
    public rgb: string;
}

export class EpisodeStatus {
    public idStatus: number;
    public statusName: string;
}

export class ArrivalType {
    public idArrivalType: number;
    public arrivalTypeName: string;
}

export class PatientNecessity {
    public idPatientNecessity: number;
    public necessityName: string;
}

export class Suspicion {
    public idSuspicion: number;
    public suspicionName: string;
    public isDefault: boolean;
    public isActive: boolean;
}

export class Origin {
    public idOrigin: number;
    public originName: string;
}

export class Comorbidity {
    public idComorbidity: number;
    public comorbidityName: string;
}

export class AgeGroup {
    public ídAgeGroup: number;
    public ageGroupName: string;
}

export class Pain {
    public idPain: number;
    public painLevel: number;
    public rgb: string;
}