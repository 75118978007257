import { MedicalPrescriptionCheckModel } from "../../models/medical-record/medical-prescription-check.model";
import { MaterialItemKitRequests } from "../../requests/pharmacy/RequestMaterial.request";

export class MedicalPrescriptionCheckStruct extends MedicalPrescriptionCheckModel
{
    public userName: string;
    public professionName: string;
    public prescriptionEstimativeStatus: string;

    public linkToNursingConsulting: boolean;

    public medicalPrescriptionStatus: number;
    public idUserScheduling: number;
    public idFrequency: number;
    public isBeforeActualSchedulingCycle: boolean;
    
    public listRequestMaterialsItemKit: MaterialItemKitRequests[] = [];
    public listIdMedicalPrescription: number[] = [];
}