<div class="table-container">
    <table class="table">
        <thead>
            <tr>
                <th>Item</th>
                <th class="centralized-content">Quantidade solicitada</th>
                <th class="centralized-content">Estoque</th>
            </tr>
        </thead>
        <tbody *ngIf="listAdditionalItems && listAdditionalItems.length > 0">
            <tr *ngFor="let item of listAdditionalItems">
                <td [ngClass]="{'error-data': !item.additionalItemForPrescriptionRelease.storageBatch}">{{item.additionalItemForPrescriptionRelease.itemName}}</td>
                <td class="centralized-content" [ngClass]="{'error-data': !item.additionalItemForPrescriptionRelease.storageBatch}">{{item.additionalItemForPrescriptionRelease.numberOfItens}}</td>
                <td class="centralized-content">{{item.additionalItemForPrescriptionRelease.storageBatch}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="listAdditionalMedicines && listAdditionalMedicines.length > 0">
            <tr *ngFor="let medicine of listAdditionalMedicines">
                <td [ngClass]="{'error-data': !medicine.additionalMedicineForPrescriptionRelease.storageBatch}">{{medicine.additionalMedicineForPrescriptionRelease.medicineDescription}}</td>
                <td class="centralized-content" [ngClass]="{'error-data': !medicine.additionalMedicineForPrescriptionRelease.storageBatch}">{{medicine.additionalMedicineForPrescriptionRelease.numberOfItens}}</td>
                <td class="centralized-content">{{medicine.additionalMedicineForPrescriptionRelease.storageBatch}}</td>
            </tr>
        </tbody>
    </table>
</div>