import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { KitWithItemsStruct } from 'src/app/shared/services/structs/pharmacy/kit-with-items.struct';
import { KitItemsModalComponent } from '../../kit-items-modal/kit-items-modal.component';
import { dateHourInCrescentOrderValidator } from 'src/app/shared/custom-validators/date-hour-form-array-order.validator';
import { ObservationScheduling } from 'src/app/shared/services/models/bed/observation-scheduling.model';
import { FrequencyEnum } from 'src/app/shared/enum/medical-record/frequency.enum';
import { MedicalPrescriptionCheckStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-prescription-check.struct';
import { KitMultiselectStruct } from 'src/app/shared/services/structs/pharmacy/kit-multiselect.struct';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { KitService } from 'src/app/shared/services/API/pharmacy/kit.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { MaterialItemKitRequests } from 'src/app/shared/services/requests/pharmacy/RequestMaterial.request';
import { StatusSchedulingEnum } from 'src/app/shared/enum/medical-record/status-scheduling.enum';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { minOneHourDifferenceValidator } from 'src/app/shared/custom-validators/min-one-hor-difference.validator';

@Component({
  selector: 'app-medicated-solution-scheduling-modal',
  templateUrl: './medicated-solution-scheduling-modal.component.html',
  styleUrls: ['./medicated-solution-scheduling-modal.component.css']
})
export class MedicatedSolutionSchedulingModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<MedicatedSolutionSchedulingModalComponent>,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    public itemService: ItemService,
    public kitService: KitService,
  ) { }

  public frequencyEnum: typeof FrequencyEnum = FrequencyEnum;
  public statusSchedulingEnum: typeof StatusSchedulingEnum = StatusSchedulingEnum;
  
  public model: FormGroup;
  public masks: Masks;
  
  public listKits: KitMultiselectStruct[] = [];
  public listItems: ItemModel[] = [];

  public medicatedSolutionPrescriptionStruct: MedicatedSolutionStruct = null;
  public observationScheduling: ObservationScheduling;

  public isLoading: boolean = false;
  public firstLoading: boolean = true;
  public hasActionsButton: boolean = false;
  public buttonToSaveDisabled: boolean = false;

  public prescriptionType: number;
  public cycleTime: number;

  public startDateCycle: Date;

  ngOnInit(): void {
    this.firstLoading = false;
    this.model = this.formBuilder.group({
      listSchedulingDate: this.formBuilder.array([], { 
        validators: [dateHourInCrescentOrderValidator, minOneHourDifferenceValidator] }),
      observation: [""],
      listMaterials: this.formBuilder.array([]),
    });

    this.observationScheduling = this.data.observationScheduling;
    this.cycleTime = this.data.cycleTime;
    this.startDateCycle = this.data.startDateCycle;
    this.prescriptionType = this.data.prescriptionType;

    if(this.data && this.data.prescriptionItem) {
      this.medicatedSolutionPrescriptionStruct = this.data.prescriptionItem;
      if(this.medicatedSolutionPrescriptionStruct.idFrequency == this.frequencyEnum.criterioMedico
        || this.medicatedSolutionPrescriptionStruct.idFrequency == this.frequencyEnum.seNecessario
        || this.medicatedSolutionPrescriptionStruct.idFrequency == this.frequencyEnum.continuo)
        this.hasActionsButton = true;
    }
    
    this.masks = this.maskService.getMasks();
    this.populateKitList();
    this.populateItemList();
    this.populate();
    this.monitorFirstDateChange();
  }
  
  submit(){
    //colocar lógica de reaprazamento depois
    const listSchedulingDateForm = this.model.get('listSchedulingDate') as FormArray;

    if(listSchedulingDateForm.errors?.dateLessThanCurrentDate){
      this.alertService.show('Erro', "As datas não podem ser menores que a data de hoje!", AlertType.error);
      return;
    }
    if(this.model.invalid){
      this.alertService.show('Erro', "Preencha todos os campos obrigatórios!", AlertType.error);
      return;
    }

    this.medicatedSolutionPrescriptionStruct.listMedicalPrescriptionCheck = [];

    if (listSchedulingDateForm) {
      
      let inclusionDatePrescription = new Date(this.medicatedSolutionPrescriptionStruct.datetimeInclusion);
      const firstDateValue = listSchedulingDateForm.at(0).get('date').value.toString();
      const firstHourValue = listSchedulingDateForm.at(0).value.hour.toString();
      if(!this.validFirstDate(firstDateValue, firstHourValue, inclusionDatePrescription)) {
        this.alertService.show('Erro', "Não é possível definir um horário inicial superior às 24h da criação da prescrição!", AlertType.error);
        return;
      }

      listSchedulingDateForm.getRawValue().forEach(x => {
        var medicalPrescriptionCheckStruct = new MedicalPrescriptionCheckStruct();
        
        let day = parseInt(x.date.substring(0, 2), 10);
        let month = parseInt(x.date.substring(2, 4), 10) - 1; // Mês é base zero
        let year = parseInt(x.date.substring(4), 10);
        let hour = parseInt(x.hour.substring(0, 2), 10);
        let minute = parseInt(x.hour.substring(2, 4), 10);

        medicalPrescriptionCheckStruct.datetimeCheckEstimative = new Date(year, month, day, hour, minute, 0);
        medicalPrescriptionCheckStruct.schedulingObservation = this.model.get('observation').value;
        medicalPrescriptionCheckStruct.idStatusScheduling = this.statusSchedulingEnum.Aprazado;
        this.medicatedSolutionPrescriptionStruct.listMedicalPrescriptionCheck.push(medicalPrescriptionCheckStruct);
      });
    }
    if (this.model.get('listMaterials')) {
      this.medicatedSolutionPrescriptionStruct.listMaterialItemKitRequests = [];
      this.model.get('listMaterials').value.forEach(x => {
        let requestItemKit = new MaterialItemKitRequests;
        requestItemKit.idRequestMaterialItemKit = x.idRequestMaterialItemKit;
        requestItemKit.idRequestMaterial = x.idRequestMaterial;
        let kitItem = x.kitItem.split('null')
        requestItemKit.idKit = kitItem[0] ? parseInt(kitItem[0]) : null;
        requestItemKit.idItem = kitItem[1] ? parseInt(kitItem[1]) : null;
        requestItemKit.quantity = x.quantity;
        if (requestItemKit.quantity <= 0) {
          this.alertService.show('Erro', "É necessário adicionar ao menos um item em cada material", AlertType.error);
          this.isLoading = false;
          return;
        }
        this.medicatedSolutionPrescriptionStruct.listMaterialItemKitRequests.push(requestItemKit);
      });
    }
    this.matDialogRef.close({prescription: this.medicatedSolutionPrescriptionStruct}); 
  }

  populate() {
    let listModelSchedulingDate = (this.model.controls['listSchedulingDate'] as FormArray);
    let listModelMaterials = (this.model.controls['listMaterials'] as FormArray);
    let inputDisable: boolean = false;
    let startDate = new Date();
    startDate.setSeconds(0);
    startDate.setMinutes(startDate.getMinutes() + 10);
    let maxLoopValue = this.cycleTime;
    
    if(this.medicatedSolutionPrescriptionStruct.treatmentValue){
      maxLoopValue *= this.medicatedSolutionPrescriptionStruct.treatmentValue;
    }

    //array de data
    if(this.medicatedSolutionPrescriptionStruct.listMedicalPrescriptionCheck
      && this.medicatedSolutionPrescriptionStruct.listMedicalPrescriptionCheck.length > 0) {
      
      this.medicatedSolutionPrescriptionStruct.listMedicalPrescriptionCheck.forEach(x => {
        x.datetimeCheckEstimative = new Date(x.datetimeCheckEstimative);
        listModelSchedulingDate.push(
          this.createInputDate(inputDisable, x.datetimeCheckEstimative)
        );
        if(this.observationScheduling.blockTimeEditingScheduling)
          inputDisable = true;
        if(x.idMedicalPrescriptionCheck != null)
          this.buttonToSaveDisabled = true;
      });
      this.model.get('observation').setValue(this.medicatedSolutionPrescriptionStruct.listMedicalPrescriptionCheck[0].schedulingObservation);
    } else if (this.medicatedSolutionPrescriptionStruct.idFrequency == this.frequencyEnum.x1Horas) {
      
      for(let i = 0; i < maxLoopValue; i++){
        listModelSchedulingDate.push(
          this.createInputDate(inputDisable, new Date(startDate))
        );
        startDate.setHours(startDate.getHours()+1);
        if(this.observationScheduling.blockTimeEditingScheduling)
          inputDisable = true;
      }
    } else if (this.medicatedSolutionPrescriptionStruct.frequencyValue != null && this.medicatedSolutionPrescriptionStruct.frequencyValue > 1) {
      
      for(let i = 0; i < maxLoopValue; i+=this.medicatedSolutionPrescriptionStruct.frequencyValue){
        listModelSchedulingDate.push(
          this.createInputDate(inputDisable, new Date(startDate))
        );
        startDate.setHours(startDate.getHours()+this.medicatedSolutionPrescriptionStruct.frequencyValue);
        if(this.observationScheduling.blockTimeEditingScheduling)
          inputDisable = true;
      }
    } else {
      this.addNextInputDate();
    }
    //array de itens adicionais
    if(this.medicatedSolutionPrescriptionStruct.listMaterialItemKitRequests) {
      this.medicatedSolutionPrescriptionStruct.listMaterialItemKitRequests.forEach(x => {
        let idItem = x.idKit ? x.idKit + 'null' : 'null' + x.idItem;
        listModelMaterials.push(
          this.createInputMaterial(x, idItem)
        );
        if(this.observationScheduling.blockTimeEditingScheduling)
          inputDisable = true;
      });
    }
    this.model.get('listSchedulingDate').updateValueAndValidity();

  }

  close(){
    this.matDialogRef.close(); 
  }

  createInputDate(disabled: boolean, datetimeCheckEstimative?: Date) {
    if (datetimeCheckEstimative != null) {
      return this.formBuilder.group({
        date: [{value: `${(datetimeCheckEstimative.getDate()).toString().padStart(2, '0')}${(datetimeCheckEstimative.getMonth()+1).toString().padStart(2, '0')}${datetimeCheckEstimative.getFullYear()}`, disabled: disabled}, Validators.required],
        hour: [{value: `${(datetimeCheckEstimative.getHours()).toString().padStart(2, '0')}${(datetimeCheckEstimative.getMinutes()).toString().padStart(2, '0')}`, disabled: disabled}, Validators.required],
      });
    }
    else {
      return this.formBuilder.group({
        date: [{value: '', disabled: disabled}, Validators.required],
        hour: [{value: '', disabled: disabled}, Validators.required],
      });
    }
  }
  
  addNextInputDate() {
    (this.model.controls['listSchedulingDate'] as FormArray).push(this.createInputDate(false));
  }

  removeLastSchedulingDate() {
    const list = this.model.get('listSchedulingDate') as FormArray;
    if (list.length > 1) 
      list.removeAt(list.length - 1); 

    this.model.get('listSchedulingDate').updateValueAndValidity();
  }

  createInputMaterial(item?: MaterialItemKitRequests, idItem?: string) {
    if (item != null) {
      return this.formBuilder.group({
        kitItem: [idItem, [Validators.required]],
        quantity: [item.quantity, [Validators.required]],
        idRequestMaterialItemKit: [item.idRequestMaterialItemKit],
        idRequestMaterial: [item.idRequestMaterial]
      });
    }
    else {
      return this.formBuilder.group({
        kitItem: [null, [Validators.required]],
        quantity: [null, [Validators.required]],
        idRequestMaterialItemKit: [null],
        idRequestMaterial: [null]
      })
    }
  }
  
  addNextInputMaterial() {
    (this.model.controls['listMaterials'] as FormArray).push(this.createInputMaterial());
  }

  removeInputMaterial(index: number) {
    (this.model.controls['listMaterials'] as FormArray).removeAt(index);
    if (this.model.get('listMaterials')['controls'].length == 0 && index != 0)
      this.addNextInputMaterial();

  }
  
  openKit(kit: KitWithItemsStruct) {
    const dialogRef = this.dialog.open(KitItemsModalComponent, {
      width: "600px",
      data: {
        kit: kit
      },
    });
  }

  populateKitList() {
    this.kitService.listAllKit().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        
        if(response.list && response.list.length > 0) {
          this.listKits = response.list;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateItemList() {
    this.itemService.listAllItem().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        this.listItems = response.listItem;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  validFirstDate(firstDateValue: string, firstHourValue: string, dateTimeInclusion: Date): boolean {
    const day = parseInt(firstDateValue.substring(0, 2), 10);
    const month = parseInt(firstDateValue.substring(2, 4), 10) - 1; // Mês é base zero
    const year = parseInt(firstDateValue.substring(4), 10);
    const hour = parseInt(firstHourValue.substring(0, 2), 10);
    const minute = parseInt(firstHourValue.substring(2, 4), 10);
    const firstDateInput = new Date(year, month, day, hour, minute, 0);
    dateTimeInclusion.setHours(dateTimeInclusion.getHours()+24)
    if(firstDateInput.getTime() < dateTimeInclusion.getTime())
      return true;
    else 
      return false;
  }

  monitorFirstDateChange() {
    const listSchedulingDateForm = this.model.get('listSchedulingDate') as FormArray;

    if (listSchedulingDateForm && listSchedulingDateForm.length > 0) {
      const firstDateControl = listSchedulingDateForm.at(0);

      firstDateControl.valueChanges.subscribe(value => {
        if (listSchedulingDateForm.length > 1) {
          for (let i = 1; i < listSchedulingDateForm.length; i++) {
            let nextDateControl = listSchedulingDateForm.at(i);

            let newDate = this.calculateNextDate(value.date, value.hour, i);

            nextDateControl.patchValue({
              date: newDate.date,
              hour: newDate.hour
            }, { emitEvent: false });
          }
        }
      });
    }
  }

  calculateNextDate(firstDate: string, firstHour: string, index: number) {
    let day = parseInt(firstDate.substring(0, 2), 10);
    let month = parseInt(firstDate.substring(2, 4), 10) - 1;
    let year = parseInt(firstDate.substring(4), 10);
    let hour = parseInt(firstHour.substring(0, 2), 10);
    let minute = parseInt(firstHour.substring(2, 4), 10);

    let baseDate = new Date(year, month, day, hour, minute);
    baseDate.setHours(baseDate.getHours() + (this.medicatedSolutionPrescriptionStruct.frequencyValue || 1) * index);

    return {
      date: `${baseDate.getDate().toString().padStart(2, '0')}${(baseDate.getMonth() + 1).toString().padStart(2, '0')}${baseDate.getFullYear()}`,
      hour: `${baseDate.getHours().toString().padStart(2, '0')}${baseDate.getMinutes().toString().padStart(2, '0')}`
    };
  }
}