import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { KitWithItemsStruct } from '../../services/structs/pharmacy/kit-with-items.struct';
import { KitService } from '../../services/API/pharmacy/kit.service';
import { AlertService, AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-kit-items-modal',
  templateUrl: './kit-items-modal.component.html',
  styleUrls: ['./kit-items-modal.component.css']
})
export class KitItemsModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<KitItemsModalComponent>,
    private kitService: KitService,
    private alertService: AlertService,
  ) { }

  public kit: KitWithItemsStruct;

  public idKit: number;

  public isLoading: boolean = true;

  ngOnInit(): void {
    if(this.data.kit) {
      this.kit = this.data.kit;
      this.isLoading = false;
    }
    else if(this.data.idKit) {
    this.getKit(this.data.idKit);
    }
  }

  close() {
    this.matDialogRef.close();
  }

  getKit(idKit: number) {
    this.kitService.getKit(idKit).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.kit = response.kit;
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

}
