<header>
    <div class="header-infos">
        <div class="header-logo">
            <mat-icon *ngIf="selectedModuleName != undefined" class="header-menu" aria-hidden="false"
                aria-label="Menu modulos" (click)="openModuleMenu(sidenavModuleMenu)">menu</mat-icon>
            <a class="link-menu" (click)="redirect(uriModuleSelectd)">
                <img class="brand" src="assets/images/logo-ToLife.png">
            </a>
        </div>
        <h1 class="module-name" (click)="redirect(uriModuleSelectd)"><b>{{ selectedModuleName }} </b></h1>
        <h1 [ngClass]="{'line-left': selectedModuleName != undefined}" class="module-name"
            (click)="redirect(uriModuleSelectd)">{{isAuthenticated ? userLoginResponse.healthUnit.healthUnitName : '' }}
        </h1>
    </div>

    <div class="actions" *ngIf="isAuthenticated == true">
        <div class="rank-actions" *ngIf="isVisibleButtonRank == true">
            <a class="link-rank" (click)="openRankInfo()">
                <mat-icon
                    [ngClass]="{'gold-icon': userRankPosition == 1, 'silver-icon': userRankPosition == 2, 'bronze-icon': userRankPosition == 3}"
                    class="rank-icon" aria-hidden="false" aria-label="rank de classificação">military_tech</mat-icon>
                <span class="rank-visibility">{{userRankPosition}}° lugar | {{userRankPoint}} pontos</span>
            </a>
        </div>
        <div class="user-actions">
            <mat-icon aria-hidden="false" aria-label="Menu apps" (click)="openSignatureManager()">
                {{managerIcon}}
            </mat-icon>
            <mat-icon [matBadge]="unreadMessages" matBadgeColor="accent" matBadgeSize="small" *ngIf="unreadMessages > 0"
                aria-hidden="false" aria-label="Menu apps" (click)="openNotification()">notifications
            </mat-icon>
            <mat-icon *ngIf="!unreadMessages" aria-hidden="false" aria-label="Menu apps" (click)="openNotification()">
                notifications
            </mat-icon>
            <mat-icon aria-hidden="false" aria-label="Menu apps"
                (click)="openModuleInfo(sidenavModuleInfos)">apps</mat-icon>
            <mat-icon aria-hidden="false" aria-label="Informações do usuário"
                (click)="openUserInfo(sidenavUserInfo)">account_circle</mat-icon>
        </div>
    </div>
</header>

<div *ngIf="!onlineOffline" class="offline">
    <span><mat-icon class="offline--icon">wifi_off</mat-icon>Você esta sem conexão com a internet no momento.</span>
</div>

<mat-drawer-container class="container-sidenav" [hasBackdrop]="true">
    <mat-drawer #sidenavModuleMenu class="module-menu-sidenav" mode="over">
        <app-module-menu *ngIf="isAuthenticated == true" [isVisible]="isVisibleModuleMenu"
            [systemMainMenu]="this.systemMainMenu" (closeModuleMenu)=closeModuleMenu()></app-module-menu>
    </mat-drawer>
</mat-drawer-container>

<app-secundary-header #secundaryHeader *ngIf="hasSecundaryHeader && episode" class="secundary-header"
    [episode]="episode" [discriminators]="discriminators" [hasCachedModule]="hasCachedModule"
    [cachedModuleName]="cachedModuleName" [painColorCode]="painColorCode" [gravityColorCode]="gravityColorCode"
    [pain]="pain" [gravity]="gravity" [isClassification]="isClassification" (restartMeasurers)="emitMeasurerRestart()">
</app-secundary-header>


<app-secundary-header-v2 #secundaryHeaderV2 *ngIf="hasSecundaryHeader && !episode" class="secundary-header"
    [name]="name" [socialName]="socialName" [birthDate]="birthDate" [formatedBirth]="formatedBirth" [age]="age"
    [patientCareLinePriorityStruct]="patientCareLinePriorityStruct" [isClassification]="isClassification"
    (restartMeasurers)="emitMeasurerRestart()">
</app-secundary-header-v2>

<app-signature-manager *ngIf="!loadingSignatures" [isMenu]="true" [isVisible]="isVisibleSignatureManager"
    [isLoading]="isLoadingSignatureManager" (setManagerIcon)="setManagerIcon($event)"
    (closeManager)="closeSignatureManager()">
</app-signature-manager>

<app-notification [listMessage]="listMessage" [isVisible]="isVisibleNotification" [isLoading]="isLoadingNotification"
    [idSelectedMessage]="idSelectedMessage" (closeNotification)=closeNotification()
    (populateNotification)="populateNotification()" (selectMessage)="selectMessage($event)">
</app-notification>

<app-rank-infos class="slide-bottom" *ngIf="isAuthenticated == true" [isVisible]="isVisibleRank"
    (closeRankInfo)="closeRankInfo()" (getRankInfosValue)="getRankInfosValue($event)" [idSector]="idSector"
    [idService]="idService" [sectorName]="sectorName" [serviceName]="serviceName">
</app-rank-infos>

<mat-drawer-container class="container-sidenav" [hasBackdrop]="true">
    <mat-drawer #sidenavUserInfo class="user-info-sidenav" mode="over" position="end">
        <app-user-infos *ngIf="isAuthenticated == true" [isVisible]="isVisibleUserInfo"
            (closeUserInfo)="closeUserInfo(sidenavUserInfo)" [menuModuleEnum]="menuModuleEnum"
            (savePreviousURL)="setPreviousURL()">
        </app-user-infos>
    </mat-drawer>
</mat-drawer-container>

<mat-drawer-container class="container-sidenav" [hasBackdrop]="true">
    <mat-drawer #sidenavModuleInfos class="module-info-sidenav" mode="over" position="end" autofocus="false">
        <app-module-infos *ngIf="isAuthenticated == true" [isVisible]="isVisibleModule"
            [menuModuleEnum]="this.menuModuleEnum" (closeModuleInfo)="closeModuleInfo()"></app-module-infos>
    </mat-drawer>
</mat-drawer-container>