import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PatientBarCodeModalComponent } from "src/app/shared/components/patient-bar-code-modal/patient-bar-code-modal.component";
import { SuspendPrescriptionScheduleModalComponent } from "src/app/shared/components/suspend-prescription-schedule-modal/suspend-prescription-schedule-modal.component";
import { StatusSchedulingEnum } from "src/app/shared/enum/medical-record/status-scheduling.enum";
import { ProcedureStatusEnum } from "src/app/shared/enum/procedure-status.enum";
import { UserRoleEnum } from "src/app/shared/enum/user-role.enum";
import { AlertService, AlertType } from "src/app/shared/services/alert.service";
import { UserGetNameService } from "src/app/shared/services/API/user/user-get-name.service";
import { MedicalProcedureCheckRequest } from "src/app/shared/services/requests/medical-record/medical-procedure-check.request";
import { VerifyPermissionResponse } from "src/app/shared/services/responses/user/verify-permission.response";
import { MedicalProcedureStruct } from "src/app/shared/services/structs/medical-record/medical-procedure.struct";
import { MedicalProcedureCheckStruct } from "src/app/shared/services/structs/orchestrator-patient/medical-procedure-check.struct";
import { UtilService } from "src/app/shared/services/util.service";

@Component({
  selector: 'app-medical-procedure-modal',
  templateUrl: './medical-procedure-modal.component.html',
  styleUrls: ['./medical-procedure-modal.component.css']
})
export class MedicalProcedureModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicalProcedureModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    public userGetNameService: UserGetNameService,
    private utilService: UtilService
  ) { }

  public model: UntypedFormGroup;
  public isLoading: boolean;
  public isReadonly: boolean;
  public checkProcedure: boolean;
  public useScheduling: boolean = false;
  public canSuspend: boolean = false;

  public checkDatetimeFulfilled: Date;
  public reasonSuspension: string;
  public checkProfessionName: string;
  public checkUserName: string;
  public procedureStatusClosed: ProcedureStatusEnum.finalizado;
  public statusSchedulingEnum: typeof StatusSchedulingEnum = StatusSchedulingEnum;

  public procedure: MedicalProcedureStruct;
  public medicalProcedureCheck: MedicalProcedureCheckStruct;
  public verifyPermissionResponse: VerifyPermissionResponse = this.data.verifyPermissionResponse;

  public closedStatus: ProcedureStatusEnum.finalizado;
  public idStatusScheduling: number;

  ngOnInit(): void {
    this.isLoading = false;
    this.isReadonly = false;
    this.checkProcedure = false;
    this.procedure = this.data.procedure;

    if (this.data.useScheduling != null)
      this.useScheduling = this.data.useScheduling;

    if (this.data.selectedMedicalPrescriptionCheck)
      this.medicalProcedureCheck = this.data.selectedMedicalProcedureCheck
    else
      this.medicalProcedureCheck = this.procedure.medicalProcedureCheck;

    this.model = this.formBuilder.group({
      checkName: [false], checkProcedure: [false]
    });

    if (this.medicalProcedureCheck) {
      this.populateProcedureCheck();
    }

    this.canSuspend = this.utilService.getRoles().some(x => x.idRole === UserRoleEnum.checagem_suspender_horário) || this.utilService.getToken().isMaster;
  }

  submit() {
    if (!this.model.get("checkName").value || !this.model.get("checkProcedure").value) {
      this.alertService.show('Erro', "É necessário verificar os campos", AlertType.error);
      return;
    }

    this.isLoading = true;


    let medicalProcedureCheckRequest: MedicalProcedureCheckRequest = new MedicalProcedureCheckRequest();
    medicalProcedureCheckRequest = this.mapToRequest();
    this.updateOrCreateCheck();
    this.matDialogRef.close({
      medicalProcedureCheckRequest: medicalProcedureCheckRequest,
      idProcedureSigtap: this.procedure.idProcedureSigtap,
    });
  }

  getProcedureCheckData() {
    this.checkDatetimeFulfilled = this.medicalProcedureCheck.datetimeCheckFulfilled;
    this.checkProfessionName = this.medicalProcedureCheck.professionName;
    this.checkUserName = this.medicalProcedureCheck.userName;
  }

  mapToRequest(): MedicalProcedureCheckRequest {
    let medicalProcedureCheckRequest: MedicalProcedureCheckRequest = new MedicalProcedureCheckRequest();

    medicalProcedureCheckRequest.checkProcedure = this.model.get('checkName').value ? this.model.get('checkName').value : false;
    medicalProcedureCheckRequest.checkProcedure = this.model.get('checkProcedure').value ? this.model.get('checkProcedure').value : false;
    medicalProcedureCheckRequest.idMedicalProcedure = this.procedure.idMedicalProcedure;
    medicalProcedureCheckRequest.idMedicalProcedureCheck = this.medicalProcedureCheck?.idMedicalProcedureCheck;
    medicalProcedureCheckRequest.reasonSuspension = this.reasonSuspension;
    medicalProcedureCheckRequest.idStatusScheduling = this.idStatusScheduling ? this.idStatusScheduling : this.statusSchedulingEnum.Concluido;

    if (this.verifyPermissionResponse) {
      medicalProcedureCheckRequest.idUserAuthorization = this.verifyPermissionResponse.idUser;
      medicalProcedureCheckRequest.idUserRequest = this.verifyPermissionResponse.idUserRequest;
      medicalProcedureCheckRequest.justification = this.verifyPermissionResponse.justification;
    }

    return medicalProcedureCheckRequest;
  }

  populateProcedureCheck() {
    this.model.get('checkName').setValue(this.medicalProcedureCheck.checkName);
    this.model.get('checkProcedure').setValue(this.medicalProcedureCheck.checkProcedure);

    this.isReadonly = (this.medicalProcedureCheck.datetimeCheckFulfilled != null || this.data.procedure.idProcedureStatus == this.procedureStatusClosed);
    if (this.isReadonly) {
      this.model.get('checkName').setValue(true);
      this.model.get('checkProcedure').setValue(true);
      this.model.get('checkName').disable();
      this.model.get('checkProcedure').disable();
    }

    this.getProcedureCheckData();
  }

  updateOrCreateCheck() {
    if (!this.medicalProcedureCheck) {
      this.procedure.medicalProcedureCheck = new MedicalProcedureCheckStruct();

      this.medicalProcedureCheck = this.procedure.medicalProcedureCheck;
    }
    this.medicalProcedureCheck.checkProcedure = this.model.get('checkProcedure').value ? this.model.get('checkProcedure').value : false;
    this.medicalProcedureCheck.checkName = this.model.get('checkName').value ? this.model.get('checkName').value : false;
  }

  close() {
    this.matDialogRef.close();
  }

  openPatientBarCodeModal() {
    const dialogRef = this.dialog.open(PatientBarCodeModalComponent, {
      data: {
        idEpisode: this.data.idEpisode,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.checkPatient) {
        this.model.get('checkName').setValue(true);
        this.model.get('checkProcedure').setValue(true);
      }
    });
  }

  suspendPrescriptionModal() {
    const dialogRef = this.dialog.open(SuspendPrescriptionScheduleModalComponent, {
      width: "600px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm && result.reasonSuspension) {
        this.reasonSuspension = result.reasonSuspension;
        this.idStatusScheduling = StatusSchedulingEnum.Suspenso;

        let medicalProcedureCheckRequest: MedicalProcedureCheckRequest = new MedicalProcedureCheckRequest();
        medicalProcedureCheckRequest = this.mapToRequest();
        this.updateOrCreateCheck();
        this.matDialogRef.close({
          medicalProcedureCheckRequest: medicalProcedureCheckRequest,
          idProcedureSigtap: this.procedure.idProcedureSigtap,
        });
      }
    });
  }
}