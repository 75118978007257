import { Component, Inject, Input, OnInit, } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../services/alert.service';
import { LocationByModuleService } from '../../services/API/flow/location-by-module.service';
import { NoModuleRoomService } from '../../services/API/flow/no-module-room.service';
import { RoomStruct } from '../../services/structs/flow/room.struct';
import { MenuModuleEnum } from '../menu/menu.module.enum';

@Component({
  selector: 'app-select-room-modal',
  templateUrl: './select-room-modal.component.html',
  styleUrls: ['./select-room-modal.component.css']
})
export class SelectRoomModalComponent implements OnInit {

  public listRoomStruct: RoomStruct[];
  public filteredListRoomStruct: RoomStruct[];
  public searchText: string;
  @Input() idModule: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<SelectRoomModalComponent>,
    private alertService: AlertService,
    private noModuleRoomService: NoModuleRoomService,
    private locationByModuleService: LocationByModuleService,
  ) { }

  public isLoading: boolean;
  private seeOccupiedRoom: boolean = false;

  ngOnInit(): void {
    this.isLoading = false;

    if (this.data.idModule == MenuModuleEnum.generic_call) {
      this.populateGenericCallerRoomSelect();
    }
    else if (this.data.idModule == MenuModuleEnum.digital_prompt_service) {
      this.populateRoomByFuncionalitySelect();
    }
    else {
      this.populateRoomByModuleSelect();
    }
  }

  onKeySearch() {
    setTimeout(() => {
      if (this.searchText) {
        this.filteredListRoomStruct = this.listRoomStruct.filter((room: RoomStruct) => {
          let roomName = room.sectorName + ' - ' + room.roomName;
          return roomName.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
        });
      } else {
        this.filteredListRoomStruct = this.listRoomStruct;
      }
    });
  }

  populateGenericCallerRoomSelect() {
    this.noModuleRoomService.listRoom().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.seeOccupiedRoom = response.seeOccupiedRoom;
        this.listRoomStruct = response.listRoomStruct;
        this.onKeySearch();
      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateRoomByModuleSelect() {
    this.isLoading = true;
    this.locationByModuleService.listRoomByModule(this.data.idModule, this.data.idFunctionalityGroup).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.listRoomStruct = response.rooms;
        this.seeOccupiedRoom = response.seeOccupiedRoom;
        response.sectors.forEach(x => {
          this.listRoomStruct.forEach(c => {
            if (x.idSector === c.idSector) {
              c.sectorName = x.sectorName;
              c.idService = x.idService;
            }
          });
        });

        this.isLoading = false;

        this.onKeySearch();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  populateRoomByFuncionalitySelect() {
    this.isLoading = true;
    this.locationByModuleService.listRoomByFunctionalityGroup(this.data.idFunctionalityGroup).subscribe({
      next: (response) => {

        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listRoomStruct = response.rooms;
        this.seeOccupiedRoom = response.seeOccupiedRoom;
        response.sectors.forEach(x => {
          this.listRoomStruct.forEach(c => {
            if (x.idSector === c.idSector) {
              c.sectorName = x.sectorName;
              c.idService = x.idService;
            }
          });
        });

        this.isLoading = false;

        this.onKeySearch();
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  close() {
    this.matDialogRef.close({});
  }

  selectItem(item: RoomStruct) {
    this.matDialogRef.close({ res: item, seeOccupiedRoom: this.seeOccupiedRoom });
  }
}